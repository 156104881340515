import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  DepositWithrawData,
  DepositWithrawDataArray,
} from "../Utils/constants";
import AuthContext from "../context/AuthContext";
import { isEmpty,toLower } from "lodash";
import { useNavigate } from "react-router-dom";
import cardtriangle from "../assets/images/card-triangle.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
const GameSlider = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    arrows: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
    ],
  };
  const ref = useRef();
  const [data, setData] = useState({});
  const [getHotData, setHotData] = useState({});
  const [activeClass, SetActiveClass] = useState("");
  const navigate = useNavigate();
  const {
    lang,
    user,
    launchEGTCasino,
    launchCasino,
    logoutUser,
    withoutLogin,
    handleShowLoginNotification
  } = useContext(AuthContext);
  const [key, setKey] = useState("sports");
  const [getHotKey, setHotKey] = useState("");

  useEffect(() => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == "sports";
        }
      })
    );

    window.addEventListener('scroll', () => {
      if(window.scrollY > 180){
       
        SetActiveClass('gamefixed');
      }else{
        SetActiveClass('');
      }
      
   });

  }, []);

  const setFunc = (type) => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == type;
        }
      })
    );
    setKey(type);
  };
 
  return (
    <div className="home-tabing-slider">
      <div className={"payment-sec "+ activeClass}  {...settings}>
        {DepositWithrawData?.map((item) => {
          return (
            <div
              onClick={() => {
                // if (isEmpty(user)) {
                //   withoutLogin();
                // } else {
                  if (!isEmpty(item?.link)) {
                    navigate(item?.link);
                  } else {
                    setFunc(item?.type);
                  }
                // }
              }}
              className={`payment-box ${key == item?.type ? "active" : ""}`}
            >
              <figure>
                <img
                  src={key == item?.type?item?.activeicons:item?.icons}
                  alt=""
               
                />
              </figure>
              <span>{lang == "bn" ? item?.titlebn : item?.title}</span>
            </div>
          );
        })}
      </div>
      <div className="main-slider-div">
        <div className="main-slider-inner-div">
         
          <ul className={key=="hotgame"?"hotgame-ul":""}>
            {data?.array?.map((res) => {
              return (
                <li style={{backgroundImage: key=="hotgame"?`url(${res?.image})`:""}}
                onClick={() => {
                  if (!isEmpty(user)) {
                     if (res?.gameTypeCheck == "sports") {
                       window.open(res?.link, "_self");
                       } 
                    else if (data?.key == "sports") {
                           launchCasino({
                             platForm: res?.platForm,
                             gameType: res?.gameType,
                             casinoType: res?.casinoType,
                             isLobbyTrue:res?.isLobbyTrue
                           }); 
                     } 
                     else if(res?.gameTypeCheck == "platForm") {
                       launchCasino({
                         platForm: res?.platForm,
                         gameType: res?.gameType,
                         casinoType: res?.casinoType,
                         isLobbyTrue:res?.isLobbyTrue
                       });
                     }
                    
                     else if(data?.key == "hotgame"){
                       launchCasino({
                         platForm: res?.platForm,
                         gameType: res?.gameType,
                         casinoType: res?.casinoType,
                       });
                     } else{
                        if (!isEmpty(user)) {
                         let subCategory=res?.platForm=="EVOLUTION"?"EVO":res?.platForm=="SEXYBCRT"?"SEXY":res?.platForm;
                         navigate("/casino?header="+toLower(res?.key)+"&subCasino="+subCategory)
                         
                        } else {
                          navigate("/login");
                        }
                     }
                   } else {
                    navigate("/login");
                   }
                 }}>
                <img src={cardtriangle} className="card-triangle"/>
                  <div
                     onClick={() => {
                     if (!isEmpty(user)) {
                        if (res?.gameTypeCheck == "sports") {
                          window.open(res?.link, "_self");
                          } 
                       else if (data?.key == "sports") {
                        
                          
                              launchCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameType,
                                casinoType: res?.casinoType,
                                isLobbyTrue:res?.isLobbyTrue
                              });
                            
                        } 
                        else if(res?.gameTypeCheck == "platForm") {
                          launchCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameType,
                            casinoType: res?.casinoType,
                            isLobbyTrue:res?.isLobbyTrue
                          });
                        }
                       
                        else if(data?.key == "hotgame"){
                          launchCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameType,
                            casinoType: res?.casinoType,
                          });
                        } else{
                           if (!isEmpty(user)) {
                            let subCategory=res?.platForm=="EVOLUTION"?"EVO":res?.platForm=="SEXYBCRT"?"SEXY":res?.platForm;
                            navigate("/casino?header="+toLower(res?.key)+"&subCasino="+subCategory)
                            
                           } else {
                             navigate("/login");
                           }
                        }
                      } else {
                       navigate("/login");
                      }
                    }}
                  >
                    {key!=="hotgame" &&
                    <>
                    <span>
                      <LazyLoadImage
                        effect="opacity"
                        alt={res?.img}
                        src={res?.image}
                      />
                    </span>
                    <p>{res?.title}</p></>
            }
                  </div>
                </li>
              );
            })}
             
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GameSlider;
