import hotIcon from "../assets/icons/icon-hot.svg";
import sportsIcon from "../assets/icons/icon-sport.svg";
import slotIcon from "../assets/icons/icon-slot.svg";
import TableIcon from "../assets/icons/icon-table.svg";
import fishicon from "../assets/icons/icon-fish.svg";
import arcadeIcon from "../assets/icons/icon-arcade.svg";
import lotteryIcon from "../assets/icons/icon-lottery.svg";
import cockfightIcon from "../assets/icons/icon-cockfighting.svg";
import CrashIcon from "../assets/icons/icon-crash.svg";
import liveCasinoIcon from "../assets/icons/icon-casino.svg";

import activehotIcon from "../assets/icons/active/icon-hot.svg";
import activeSportsIcon from "../assets/icons/active/icon-sport.svg";
import activeSlotIcon from "../assets/icons/active/icon-slot.svg";
import activeTableIcon from "../assets/icons/active/icon-table.svg";
import activeFishicon from "../assets/icons/active/icon-fish.svg";
import activeArcadeIcon from "../assets/icons/active/icon-arcade.svg";
import activeLotteryIcon from "../assets/icons/active/icon-lottery.svg";
import activeCockfightIcon from "../assets/icons/active/icon-cockfighting.svg";
import activeCrashIcon from "../assets/icons/active/icon-crash.svg";
import ActiveLiveCasinoIcon from "../assets/icons/active/icon-casino.svg";


import AccountIcon from "../assets/icons/icon-account.svg";
import withdrawIcon from "../assets/icons/icon-withdrawal.svg";
import DepositIcon from "../assets/icons/icon-deposit.svg";

import liveCasino1 from "../assets/imagesNew/live_casino1.webp";
import liveCasino2 from "../assets/imagesNew/live_casino2.webp";
import bannerSlider1 from "../assets/imagesNew/banner-slider1.jpg";
import bannerSlider2 from "../assets/imagesNew/banner-slider2.jpg";

import jilliIcon from "../assets/imagesNew/jilli.webp";
import Jili from "../assets/imagesNew/jilii.png";
import esport from "../assets/images/esport.webp";
import cashRocket from "../assets/images/cash-rocket.webp";
import sevenupsevendown from "../assets/images/7up7down.webp";
import sicbo from "../assets/images/sicbo.webp";
import cardmatka from "../assets/images/cardmatka.webp";
import feature1 from "../assets/images/feature-1.jpg";
import feature2 from "../assets/images/feature-2.jpg";
import feature3 from "../assets/images/feature-3.jpg";
import feature4 from "../assets/images/feature-4.jpg";
import feature5 from "../assets/images/feature-5.jpg";
import feature6 from "../assets/images/feature-6.jpg";
import feature7 from "../assets/images/feature-7.jpg";
import feature8 from "../assets/images/feature-7.jpg";




import iconExchange from "../assets/images/provider-cricket.png";
import iconSportbook from "../assets/images/icon-sportbook.svg";
import iconSboSport from "../assets/images/icon-sbov2.svg";
import iconHorsebook from "../assets/images/icon-horsebook.svg";
import iconVirtual from "../assets/images/icon-virtual.svg";
import iconKabaddi from "../assets/images/icon-kabaddi.svg";
import velkieLogo from "../assets/images/logo-velki.png";

import fc from "../assets/images/fc.png";
import jdb from "../assets/images/jdb.png";
import km from "../assets/images/km.png";
import ludo from "../assets/images/ludo.png";
import pg from "../assets/images/pg.png";
import pt from "../assets/images/pt.png";
import ps from "../assets/images/provider-ps.png";
import rt from "../assets/images/rt.png";
import sexy from "../assets/images/sexy.png";
import sg from "../assets/images/sg.png";
import venus from "../assets/images/venus.png";
import hotRoad from "../assets/images/provider-awcmhotroad.png";
import cockfightIconCasino from "../assets/images/provider-awcmsv388.png";


import evo from "../assets/images/evo.png";
import sv from "../assets/images/sv.webp";
import pp from "../assets/images/pp.png";
import yl from "../assets/images/yl.png";
import yesbingo from "../assets/images/provider-awcmyesbingo.png";
import rich88 from "../assets/images/provider-rich88.png";
import ka from "../assets/images/provider-ka.png";
import cq9 from "../assets/images/provider-cq9.png";
import netent from "../assets/images/provider-netent.png";
import worldmatch from "../assets/images/provider-worldmatch.png";
import fastspinn from "../assets/images/provider-awcmfastspin.png";
import joker from "../assets/images/provider-joker.png";
import p8 from "../assets/images/provider-awcmp8.png";
import png from "../assets/images/provider-playngo.png";
import spribe from "../assets/images/provider-jdbaspribe.png";
import bpoker from "../assets/images/provider-bpoker.png";
import number from "../assets/images/provider-number.png";


import evoNew from "../assets/images/evolution.webp";
import ezugiNew from "../assets/images/evolution-new.webp";
import jiliNew from "../assets/images/jililobby.webp";
import pragmaticNew from "../assets/images/pragmatic-play.webp";
import aeSexy from "../assets/images/aeSexy.webp";
import horseRacing from "../assets/images/horse-racing.gif";
import ptNew from "../assets/images/ptNew.jpg";
import bgNew from "../assets/images/BigG.jpg";



import pakistanFlag from "../assets/images/flag-symbolism-Pakistan-design-Islamic.png";
import camodiaFlag from "../assets/images/Flag-Cambodia.png";
import philippinesFlag from "../assets/images/Flag_of_philippines.svg.jpg";
import brazilFlag from "../assets/images/Flag_of_Brazil.svg.png";
import indiaFlag from "../assets/images/Flag_of_India.svg.png";
import bangladeshFlag from "../assets/images/Flag_of_Bangladesh.svg.png";


// hotgame Image
import hot1 from "../assets/images/hot-casino/hot1.webp";
import hot2 from "../assets/images/hot-casino/hot2.webp";
import hot3 from "../assets/images/hot-casino/hot3.webp";
import hot4 from "../assets/images/hot-casino/hot4.webp";
import hot5 from "../assets/images/hot-casino/hot5.webp";
import hot6 from "../assets/images/hot-casino/hot6.webp";
import hot7 from "../assets/images/hot-casino/hot7.webp";
import hot8 from "../assets/images/hot-casino/hot8.webp";
import hot9 from "../assets/images/hot-casino/hot9.webp";
import hot10 from "../assets/images/hot-casino/hot10.webp";
import hot11 from "../assets/images/hot-casino/hot11.webp";
import hot12 from "../assets/images/hot-casino/hot12.webp";
import hot13 from "../assets/images/hot-casino/hot13.webp";
import hot14 from "../assets/images/hot-casino/hot14.webp";
import hot15 from "../assets/images/hot-casino/hot15.webp";


import HomeIcon from "../assets/icons/icon-home.svg";
import exchangeIcon from "../assets/icons/icon-cricket.svg";
import referralIcon from "../assets/icons/icon-referral.svg";
import promotionIcon from "../assets/icons/icon-promotion.svg";

import jdbIcon from "../assets/imagesNew/jdb.png";
import superAce from "../assets/imagesNew/superace.webp";
import moneyComing from "../assets/imagesNew/moneycoming.webp";
import fortuneGems from "../assets/imagesNew/fortune-gems.webp";

import ppIcon from "../assets/imagesNew/pp.webp";
import spadeIcon from "../assets/imagesNew/spade.png";

import popular1 from "../assets/images/popular-casino/crazytime.webp";
import popular2 from "../assets/images/popular-casino/supersicbo.webp";
import popular3 from "../assets/images/popular-casino/funkytime.webp";
import popular4 from "../assets/images/popular-casino/andarbahar.webp";
import popular5 from "../assets/images/popular-casino/cointoss.webp";
import popular6 from "../assets/images/popular-casino/dragontiger.webp";
import popular7 from "../assets/images/popular-casino/7up7down.webp";
import popular8 from "../assets/images/popular-casino/aviator.webp";
import popular9 from "../assets/images/popular-casino/crazycoinflip.webp";
import popular10 from "../assets/images/popular-casino/lightingroulette.webp";
import popular11 from "../assets/images/popular-casino/monopoly.webp";

import iconhotroad from "../assets/images/hotroad.png";
import betgames from "../assets/images/BETGAMES.webp";
import sv388 from "../assets/images/SV388.webp";
import fastspin from "../assets/images/FASTSPIN.webp";
import btg from "../assets/images/btg.png";
import nlcLogo from "../assets/images/nl-yellow.svg";
import dragonsoft from "../assets/images/DRAGONSOFT.webp";
import ViaCasino from "../assets/images/ViaCasino.png";
import iloveyou from "../assets/images/iloveyou.png";
import ladyluck from "../assets/images/ladyluck.png";
import pinnacle from "../assets/images/pinnacle.png";
import helpers from "./helpers";

const obj = {

  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
};
export default obj;
export const priceFormat = (value) => {
  return new Intl.NumberFormat().format(value);
};

export const CountryFlagBanner = [
  { id: 1, banner: pakistanFlag,title:"Pakistan" },
  { id: 2, banner: camodiaFlag ,title:"camodia"},
  { id: 3, banner: philippinesFlag ,title:"philippines"},
  { id: 4, banner: brazilFlag ,title:"brazil"},
  { id: 5, banner: indiaFlag,title:"india" },
  { id: 6, banner: bangladeshFlag ,title:"bangladesh"},
];
export const LiveCasinoData = [
  {
    id: 1,
    icons: liveCasino2,
    title: "AE SEXY",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-002",
  },
  {
    id: 2,
    icons: liveCasino1,
    title: "WE",
    titlebn: "আমরা",
    platForm: "1006",
    gameType: "egtGame",
    gameTypeNew: "1",
    casinoType: "LIVE CASINO",
  },

  {
    icons: ezugiNew,
    title: "Ezugi",
    titlebn: "এজুগি",
    type: "ezugi",
    key: "live",
    gameType: "egtGame",
    platForm: "203",
    gameTypeNew: "1",
    casinoType: "LIVE CASINO",
  },
  {
    icons: bgNew,
    title: "BG",
    titlebn: "বি.জি",
    type: "bg",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "BG",
  },
  {
    icons: horseRacing,
    title: "HOUSE BOOK",
    titlebn: "হাউস বুক",
    type: "housebook",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "HORSEBOOK",
  },
  {
    icons: jiliNew,
    title: "JILI",
    titlebn: "জিলি",
    type: "jili",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "JILI",
  },
  {
    icons: pragmaticNew,
    title: "PP",
    titlebn: "পিপি",
    type: "pp",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "PP",
  },
  {
    icons: ptNew,
    title: "PT",
    titlebn: "পিটি",
    type: "pt",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "PT",
  },
  {
    icons: evoNew,
    title: "Evo",
    titlebn: "ইভো",
    type: "evo",
    key: "live",
    gameType: "egtGame",
    platForm: "1006",
    gameTypeNew: "1",
    casinoType: "LIVE CASINO",
  },
  {
    icons: aeSexy,
    title: "SEXY",
    titlebn: "সেক্সি",
    type: "sexy",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "SEXYBCRT",
  },
  {
    icons: sv,
    title: "SV388",
    titlebn: "এসভি৩৮৮",
    type: "sv",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "SV388",
  },
  {
    icons: venus,
    title: "VENUS",
    titlebn: "শুক্র",
    type: "venus",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "VENUS",
  },
];
export const BannerSliderData = [
  { id: 1, bannerImg: bannerSlider1 },
  { id: 2, bannerImg: bannerSlider2 },
];
export const SlotData = [
  {
    id: 2,
    icons: superAce,
    title: "Super Ace",
    titlebn: "সুপার এস",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-027",

  },{
    id: 2,
    icons: moneyComing,
    title: "Money Coming",
    titlebn: "টাকা আসছে",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-029",

  },
  {
    id: 2,
    icons: fortuneGems,
    title: "fortune Gems",
    titlebn: "ভাগ্য রত্ন",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-043",

  },
  {
    id: 2,
    icons: jdbIcon,
    title: "JDB",
    titlebn: "জেডিবি",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JDB",
  },
  {
    id: 3,
    icons: jilliIcon,
    title: "JILI",
    titlebn: "জিলি",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
  },
  {
    id: 4,
    icons: ppIcon,
    title: "PP",
    titlebn: "পৃ",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "PP",
  },
  {
    id: 5,
    icons: spadeIcon,
    title: "SPADE",
    titlebn: "কোদাল",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "SPADE",
  },
  {
    id: 6,
    icons: evoNew,
    title: "EVO",
    titlebn: "ইভো",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "",
    
  },
 
];
export const PopularCasino = [
  {
    id: 1,
    icons: popular1,
    title: "Crazy Time",
    titlebn: "ক্রেজি টাইম",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-006",
  },
  {
    id: 1,
    icons: popular2,
    title: "Super Sic Bo",
    titlebn: "সুপার সিকবো",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-005",
  },
  {
    id: 3,
    icons: popular3,
    title: "Funky Time",
    titlebn: "ফানকি টাইম",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-183",
  },
  {
    id: 4,
    icons: popular4,
    title: "Andar Bahar",
    titlebn: "আন্দার বাহার",
    gameTypeCheck: "platForm",
    gameType: "TABLE",
    platForm: "JILI",
    casinoType: "JILI-TABLE-003",
  },
  {
    id: 5,
    icons: popular5,
    title: "Coin Toss",
    titlebn: "কয়েন টস",
    gameTypeCheck: "platForm",
    gameType: "TABLE",
    platForm: "KINGMAKER",
    casinoType: "KM-TABLE-036",
  },
  {
    id: 6,
    icons: popular6,
    title: "Dragon Tiger",
    titlebn: "ড্রাগন টাইগার",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-034",
  },
  {
    id: 7,
    icons: popular7,
    title: "7up7down",
    titlebn: "৭ আপ ৭ ডাউন",
    gameTypeCheck: "platForm",
    gameType: "TABLE",
    platForm: "JILI",
    casinoType: "JILI-TABLE-011",
  },
  {
    id: 8,
    icons: popular8,
    title: "Aviatar ",
    titlebn: "অ্যাভায়টর",
    gameTypeCheck: "platForm",
    gameType: "",
    platForm: "",
    casinoType: "",
  },
  {
    id: 9,
    icons: popular9,
    title: "Crazy Coin Flip",
    titlebn: "ক্রেজি কয়েন ফ্লিপ",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-014",
  },
  {
    id: 10,
    icons: popular10,
    title: "Lightning Roulette",
    titlebn: "লাইটেনিং রোলেট",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-040",
  },
  {
    id: 11,
    icons: popular11,
    title: "MONOPOLY",
    titlebn: "মনোপলি",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "EVOLUTION",
    casinoType: "EVOLUTION-LIVE-006",
  },
 
];
export const DepositWithrawDataNew = [
  {
    id: 1,
    icons: DepositIcon,
    title: "Deposit",
    link: "/deposit",
    titlebn: "জমা",
  },
  {
    id: 2,
    icons: withdrawIcon,
    title: "Withdraw",
    link: "/withdraw",
    titlebn: "প্রত্যাহার করুন",
  },
];
export const DepositWithrawData = [
  {
    id: 1,
    icons: hotIcon,
    activeicons:activehotIcon,
    title: "Hot",
    type: "hotgame",
    titlebn: "গরম খেল",
  },
  {
    id: 1,
    icons: sportsIcon,
    activeicons: activeSportsIcon,
    title: "Sports",
    type: "sports",
    titlebn: "বিনিময়",
  },
  {
    id: 2,
    icons: liveCasinoIcon,
    activeicons: ActiveLiveCasinoIcon,
    title: "Casino",
    titlebn: "লাইভ ক্যাসিনো",
    type: "casino",
  },
  { id: 3, 
    icons: slotIcon,
    activeicons: activeSlotIcon,
     title: "Slot",
      titlebn: "স্লট",
       type: "slot" 
      },
  { id: 4, 
    icons:TableIcon,
    activeicons: activeTableIcon,
    title: "Table",
    titlebn: "টেবিল",
    type: "table" 
  },
  { id: 4, 
    icons:CrashIcon,
    activeicons: activeCrashIcon,
    title: "Crash",
    titlebn: "টেবিল",
    type: "crash" 
  },
  {
    id: 6,
    icons: lotteryIcon,
    activeicons: activeLotteryIcon,
    title: "Lottery",
    titlebn: "খেলাধুলা",
    type: "lottery",
  },
  {
    id: 5,
    icons: fishicon,
    activeicons: activeFishicon,
    title: "Fishing",
    titlebn: "মাছ ধরা",
    type: "fishing",
  },
  {
    id: 5,
    icons: arcadeIcon,
    activeicons: activeArcadeIcon,
    title: "Arcade",
    type: "egame",
    titlebn: "স্পোর্টসবুক",
  },
  
  {
    id: 5,
    icons: cockfightIcon,
    activeicons: activeCockfightIcon,
    title: "CockFight",
    type: "cockfight",
    titlebn: "স্পোর্টসবুক",
  },
  
  
];
export const DepositWithrawDataArray = [
  {
    id: 1,
    title: "Hot",
    key: "hotgame",
    titlebn: "গরম খেল",
    array: [
      
      {
        id: 1,
        image: hot1,
        title: "Aviator",
        type: "Aviator",
        gameTypeCheck: "platForm",
        platForm: "SPRIBE",
        gameType: "EGAME",
        casinoType: "SPRIBE-EGAME-001",
      },
      {
        id: 2,
        image: hot2,
        title: "Super Ace",
        type: "SuperAce",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-027",
      },
      {
        id: 3,
        image: hot3,
        title: "Fortune Gems 2",
        type: "FortuneGems2",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-076",
      },
      
      {
        id: 4,
        image: hot4,
        title: "Crazy Time",
        type: "crazytime",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-006",
      },
      {
        id: 5,
        image: hot5,
        title: "Funky Time",
        type: "funkytime",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-183",
      },
      {
        id: 6,
        image: hot6,
        title: "Boxing King",
        type: "BoxingKing",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-031",
      },
      {
        id: 7,
        image: hot7,
        title: "Money Coming",
        type: "MoneyComing",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-029",
      },
      {
        id: 8,
        image: hot8,
        title: "Super Ace Deluxe",
        type: "SuperAceDeluxe",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-102",
      },
      {
        id: 8,
        image: hot9,
        title: "Jetx",
        type: "hetx",
        gameTypeCheck: "platForm",
        platForm: "SPRIBE",
        gameType: "EGAME",
        casinoType: "SPRIBE-EGAME-001",
      },
      {
        id: 9,
        image: hot10,
        title: "7up7down",
        type: "7up7down",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-011",
      },
      {
        id: 10,
        image: hot11,
        title: "Aviatrix",
        type: "Aviatrix",
        gameTypeCheck: "platForm",
        platForm: "SPRIBE",
        gameType: "EGAME",
        casinoType: "SPRIBE-EGAME-001",
      },
      {
        id: 11,
        image: hot12,
        title: "Money Coming Expand Bets",
        type: "MoneyComingExpandBets",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-107",
      },
      {
        id: 12,
        image: hot13,
        title: "Mega Wheel",
        type: "MegaWheel",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-038",
      },
      {
        id: 13,
        image: hot14,
        title: "Fortune Gems 3",
        type: "FortuneGems3",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-096",
      },
      {
        id: 13,
        image: hot15,
        title: "Baccarat",
        type: "Baccarat",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-014",
      },
    ],
  },
  {
    id: 1,
    title: "Sports",
    key: "sports",
    titlebn: "বিনিময়",
    array: [
      {
        image: iconExchange,
        title: "BETSWIZ",
        gameTypeCheck: "sports",
        link: `${"https://bxawscf."+helpers?.getDomain()+"/"}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
      // {
      //   image: iconExchange,
      //   title: "Exchange 2",
      //   gameTypeCheck: "sports",
      //   link: `${process.env.REACT_APP_VELKIE_URL}bkash/${localStorage.getItem(
      //     "token"
      //   )}`,
      // },
      {
        image: iconSportbook,
        title: "I-Sports",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
      // {
      //   image: iconSboSport,
      //   title: "SBO Sports",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
      {
        image: iconHorsebook,
        title: "Horsebook",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "HORSEBOOK",
        gameType: "LIVE",
        casinoType: "HRB-LIVE-001",
      },
      // {
      //   image: iconVirtual,
      //   title: "Virtual",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
      // {
      //   image: iconKabaddi,
      //   title: "Kabaddi",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
    ],
  },
  {
    id: 2,
    title: "Casino",
    key: "casino",
    titlebn: "লাইভ ক্যাসিনো",
    array: [
      {
        image: evo,
        title: "EVOLUTION",
        type: "evo",
        key: "live",
        // gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-006",
        isLobbyTrue: true,
      },
      {
        image: sexy,
        title: "SEXYBCRT",
        type: "sexy",
        key: "live",
        // gameTypeCheck: "platForm",
        platForm: "SEXYBCRT",
        gameType: "LIVE",
        casinoType: "MX-LIVE-006",
        isLobbyTrue:true
      },
      // {
      //   image: Jili,
      //   title: "JILI",
      //   type: "jili",
      //   key: "live",
      //   platForm: "JILI",
      // },
      // {
      //   image: km,
      //   title: "KINGMAKER",
      //   type: "kingmaker",
      //   key: "live",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      // },
      // {
      //   image: ludo,
      //   title: "LUDO",
      //   type: "ludo",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "KINGMAKER",
      //   gameType: "TABLE",
      //   casinoType: "KM-TABLE-060",
      // },
      // {
      //   image: iconHorsebook,
      //   title: "HOORSEBOOK",
      //   type: "horsebook",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "HORSEBOOK",
      //   gameType: "LIVE",
      //   casinoType: "HRB-LIVE-001",
      // },
      {
        image: iconhotroad,
        title: "HOTROAD",
        type: "hotroad",
        key: "live",
        // gameTypeCheck: "platForm",
        platForm: "HOTROAD",
        gameType: "LIVE",
        casinoType: "HOTROAD-LIVE-001",
        isLobbyTrue: true,
      },
      {
        image: pt,
        title: "PLAYTECH",
        type: "pt",
        key: "live",
        platForm: "PT",
      },
      //  {
      //   image: worldmatch,
      //   title: "WM",
      //   type: "WorldMatch",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-011",
      // },
      {
        image: betgames,
        title: "BG",
        type: "bg",
        key: "live",
        // gameTypeCheck: "platForm",
        platForm: "BG",
        gameType: "LIVE",
        casinoType: "",
        
      },
      
      // {
      //   image: rt,
      //   title: "REDTIGER",
      //   type: "rt",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "RT",
      //   gameType: "SLOT",
      //   casinoType: "RT-SLOT-001",
      //   isLobbyTrue: true,
      // },
      // {
      //   image: sg,
      //   title: "SPADEGAMING",
      //   type: "spade",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPADE",
      //   gameType: "SLOT",
      //   casinoType: "SG-SLOT-001",
      //   isLobbyTrue: true,
      // },
      // {
      //   image: fc,
      //   title: "FC",
      //   type: "fc",
      //   key: "live",
      //   gameType: "LIVE",
      //   platForm: "FC",
      // },
      // {
      //   image: jdb,
      //   title: "JDB",
      //   type: "jdb",
      //   key: "live",
      //   gameType: "LIVE",
      //   platForm: "JDB",
      // },
      // {
      //   image: yl,
      //   title: "YL",
      //   type: "yl",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   gameType: "LIVE",
      //   platForm: "YL",
      // },
      // {
      //    image: providermg,
      //   title: "MG",
      //   type: "spade",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPADE",
      //   gameType: "SLOT",
      //   casinoType: "SG-SLOT-001",
      //   isLobbyTrue: true,
      // },
      
      // {
      //   image: awcmdg,
      //   title: "DG",
      //   type: "dragonsoft",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "DRAGOONSOFT",
      //   gameType: "",
      //   casinoType: "",
      // },
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "live",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-001",
      },
      

      // {
      //   image: yesbingo,
      //   title: "YESBINGO",
      //   type: "yesbingo",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "YESBINGO",
      // },

      // {
      //   image: fastspin,
      //   title: "FASTSPIN",
      //   type: "FastSpin",
      //   key: "live",

      //   gameType: "EGAME",
      //   platForm: "JDB",
      //   casinoType: "JDB-EGAME-018",
      // },
      {
        image: esport,
        title: "E1SPORT",
        type: "e1sports",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "ESPORTS",
        platForm: "E1SPORT",
        casinoType: "E1-ESPORTS-001",
      },
      // {
      //   image: biggaming,
      //   title: "BIGGAMING",
      //   type: "biggaming",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "BG",
      //   gameType: "LIVE",
      //   casinoType: "BG-LIVE-001",
      //   isLobbyTrue: true,
      // },
      // {
      //   image: pg,
      //   title: "PGSOFT",
      //   type: "pg",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PG",
      // },

      {
        image: sv388,
        title: "SV388",
        type: "sv",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SV388",
        gameType: "LIVE",
        casinoType: "SV-LIVE-001",
      },
      {
        image: ViaCasino,
        title: "VIACASINO",
        type: "VIACASINO",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "VIACASINO",
        gameType:"LIVE",
        casinoType:"VIACASINO-LIVE-001",
        isLobbyTrue:true
       

      },
      // {
      //   image: sabavrtual,
      //   title: "SABAVIRTUAL",
      //   type: "SABAVIRTUAL",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
      // { image: ezugi,
      //   title: "Ezugi",
      //   type: "ezugi",
      //   key: "live",
      //   gameType: "egtGame",
      //   platForm: "Ezugi",
      //   gameTypeNew: "",
      //   casinoType: "",
      // },
      // {
      //   image: superspade,
      //   title: "SUPERSPADE",
      //   type: "SUPERSPADE",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   gameType: "",
      //   platForm: "SPADE",
      //   casinoType: "",
      // },
      //  {
      //       image: evoplay,
      //       title: "EVOPLAY",
      //       type: "EVOPLAY",
      //       key: "live",
      //       gameType: "egtGame",
      //       platForm: "EVO",
      //       gameTypeNew: "",
      //       casinoType: "LIVE CASINO",
      //  },

      //  {
      //   image: onetouch,
      //   title: "ONETOUCH",
      //   type: "ONETOUCH",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "ONETOUCH",
      // },
      // {
      //   image: spribe,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      //   gameType: "",
      //   casinoType: "",
      // },
      // {
      //   image: bombaylive,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      // },
      // {
      //   image: royalgaming,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      // },
    ],
  },
  {
    id: 2,
    title: "Slot",
    key: "slot",
    titlebn: "স্লট",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JILI",
      },
      {
        image: pg,
        title: "PG",
        type: "pg",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PG",
      },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JDB",
      },
    //   {
    //     image: providermg,
    //    title: "MG",
    //    type: "spade",
    //    key: "slot",
    //    gameTypeCheck: "platForm",
    //    platForm: "SPADE",
    //    gameType: "SLOT",
    //    casinoType: "SG-SLOT-001",
    //    isLobbyTrue: true,
    //  },
     {
      image: fastspin,
      title: "FASTSPIN",
      type: "FastSpin",
      key: "slot",
      // gameTypeCheck: "platForm",
      gameType: "SLOT",
      platForm: "FASTSPIN",
      
    },
    // {
    //   image: providernextspin,
    //   title: "NEXTSPIN",
    //   type: "FastSpin",
    //   key: "live",
    //   gameTypeCheck: "platForm",
    //   gameType: "SLOT",
    //   platForm: "JDB",
    //   casinoType:"JDB-SLOT-031"
    // },
    {
      image: fc,
      title: "FC",
      type: "fc",
      key: "slot",
      // gameTypeCheck: "platForm",
      gameType: "SLOT",
      platForm: "FC",
    },
    {
      image: sg,
      title: "SG",
      type: "spade",
      key: "slot",
      // gameTypeCheck: "platForm",
      gameType: "SLOT",
      platForm: "SPADE",
    },
    {
      image: yesbingo,
      title: "Yes Bingo",
      type: "yesbingo",
      key: "slot",
      // gameTypeCheck: "platForm",
      gameType: "SLOT",
      platForm: "YESBINGO",
    },
    {
      image: rt,
      title: "RT",
      type: "rt",
      key: "slot",
      // gameTypeCheck: "platForm",
      gameType: "",
      platForm: "RT",
    },
    {
      image: pt,
      title: "PT",
      type: "pt",
      key: "slot",
      // gameTypeCheck: "platForm",
      gameType: "SLOT",
      platForm: "PT",
    },
      // {
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      //   casinoType: "PP-SLOT-001",
      // },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      // },

      //  {
      //   image: worldmatch,
      //   title: "WorldMatch",
      //   type: "WorldMatch",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-012",
      // },
      
      {

        image: joker,
        title: "Joker",
        type: "Joker",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JOKER",
        casinoType: "",
      },
      

      //   {
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FC",
      //   casinoType: "FC-SLOT-001",
      // },

      {
        image: netent,
        title: "NETENT",
        type: "NETENT",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "NETENT",
        platForm: "",
        casinoType: "",
      },

  {
        image: p8,
        title: "P8",
        type: "PLAYSTAR",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PLAY8",
        casinoType: "",
      },

//  {
//         image: png,
//         title: "PNG",
//         type: "PNG",
//         key: "slot",
//         gameTypeCheck: "platForm",
//         gameType: "TABLE",
//         platForm: "JILI",
//         casinoType: "JILI-TABLE-004",
//       },

//  {
//         image: ladyLuck,
//         title: "Ladyluck",
//         type: "FastSpin",
//         key: "slot",
//         gameTypeCheck: "platForm",
//         gameType: "SLOT",
//         platForm: "JDB",
//         casinoType:"JDB-SLOT-079"
//       },
      
      {
        image: btg,
        title: "BTG",
        type: "btg",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "BTG",
      },

     
      {
        image: km,
        title: "KINGMAKER",
        type: "kingmaker",
        key: "slot",
        gameType: "SLOT",
        platForm: "KINGMAKER",
      },
     
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PP",
      },
      {
        image: dragonsoft,
        title: "DRAGOONSOFT",
        type: "dragoonsoft",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "DRAGOONSOFT",
      },

      // {
      //   image: evoplay,
      //   title: "EVOPLAY",
      //   type: "EVOPLAY",
      //   key: "slot",
      //   gameType: "egtGame",
      //   platForm: "EVO",
      //   gameTypeNew: "",
      //   casinoType: "LIVE CASINO",
      // },

      
      // {
      //   image: onetouch,
      //   title: "ONETOUCH",
      //   type: "ONETOUCH",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "ONETOUCH",
      // },

     
     
      {
        image: nlcLogo,
        title: "NLC",
        type: "NLC",
        key: "SLOT",
        // gameTypeCheck: "platForm",
        platForm: "NLC",
        gameType:"SLOT"
      },
     
      {
        image: iloveyou,
        title: "ILOVEU",
        type: "ILOVEU",
        key: "slot",
        gameTypeCheck: "platForm",
        platForm: "ILOVEU",
      },
      
      {
        image: ladyluck,
        title: "LADYLUCK",
        type: "LADYLUCK",
        key: "slot",
        gameTypeCheck: "platForm",
        platForm: "LADYLUCK",
      },
     
      
    ],
  },
  {
    id: 3,
    title: "Table",
    key: "table",
    titlebn: "টেবিল",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "table",
        // gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "JILI",
      },
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "table",
        // gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },

       {
        image: spribe,
        title: "SPRIBE",
        type: "SPRIBE",
        key: "table",
        // gameTypeCheck: "platForm",
        platForm: "SPRIBE",
        gameType: "",
        casinoType: "",
      },
      // ,{
      //     image: cq9,
      //     title: "CQ9",
      //     type: "CQ9",
      //     key: "table",
      //     gameTypeCheck: "platForm",
      //     gameType: "TABLE",
      //     platForm: "KINGMAKER",
      //     casinoType: "KINGMAKER-TABLE-006",
      //   },
      //   {
      //       image: rich88,
      //       title: "RICH88",
      //       type: "RICH88",
      //       key: "table",
      //       gameTypeCheck: "platForm",
      //       gameType: "SLOT",
      //     platForm: "JDB",
      //     casinoType:"JDB-SLOT-072"
      //     },
          
      
      //   {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-004",

      // },{
      //   image: worldmatch,
      //   title: "WorldMatch",
      //   type: "WorldMatch",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-005",
      // },
      {
        image: pg,
        title: "PG",
        type: "pg",
        key: "TABLE",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PG",
        gameType: "TABLE",
      },
      // {
      //   image: png,
      //   title: "PNG",
      //   type: "PNG",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "JILI",
      //   casinoType: "JILI-TABLE-004",
      // },
      // {
      //     image: bpoker,
      //     title: "Bpoker",
      //     type: "bpoker",
      //     key: "slot",
      //     gameTypeCheck: "platForm",
      //     gameType: "TABLE",
      //     platForm: "JILI",
      //   },
      // {
      //   image: jdb,
      //   title: "JDB",
      //   type: "jdb",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "JDB",
      // },
      // {
      //   image: rt,
      //   title: "REDTIGER",
      //   type: "rt",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "RT",
      // },
      {
        image: netent,
        title: "NETENT",
        type: "NETENT",
        key: "table",
        // gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "NETENT",
        casinoType: "",
      },
     

      
      // {
      //   image: ludo,
      //   title: "LUDO",
      //   type: "ludo",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   platForm: "KINGMAKER",
      //   gameType: "TABLE",
      //   casinoType: "KM-TABLE-060",
      // },
      // {
      //   image: spribe,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "SPRIBE",
      //   casinoType: "SPRIBE-TABLE-001",
      // },
      // {
      //   image: royalgaming,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "TABLE",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      //   gameType: "TABLE",
      // },
     
      // {
      //   image: bombaylive,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "TABLE",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      //   gameType: "TABLE",
      // },
      
    ],
  },
  {
    id: 3,
    title: "Crash",
    key: "crash",
    titlebn: "টেবিল",
    array: [
      {
         image: spribe,
        title: "SPRIBE",
        type: "SPRIBE",
        key: "crash",
        // gameTypeCheck: "platForm",
        platForm: "SPRIBE",
        gameType: "",
        casinoType: "",
      },
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "crash",
        // gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "JILI",
      },
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "crash",
        // gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "crash",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PP",
      },
    ],
  },
  {
    id: 4,
    title: "Fishing",
    key: "fishing",
    type: "fishing",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "fishing",
        gameType: "FH",
        platForm: "JILI",
      },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-019",

      // },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "fishing",
        gameType: "FH",
        platForm: "JDB",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "fishing",
        // gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "FC",
      },
      
      
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      {
        image: joker,
        title: "Joker",
        type: "Joker",
        key: "fishing",
        // gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JOKER",
        casinoType: "",
      },
      // {
      //   image: p8,
      //   title: "P8",
      //   type: "P8",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      {
        image: yesbingo,
        title: "YELLOWBAT",
        type: "yl",
        key: "fishing",
        // gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YESBINGO",
      },
      // {
      //   image: betgames,
      //   title: "BG",
      //   type: "bg",
      //   key: "fishing",
      //   gameTypeCheck: "platForm",
      //   platForm: "BG",
      //   gameType: "LIVE",
      //   casinoType: "BG-LIVE-001",
      //   isLobbyTrue: true,
      // },
      

     
      {
        image: sg,
        title: "Spade",
        type: "spade",
        key: "fishing",
        // gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "SPADE",
      },
      
    ],
  },
  
  {
    id: 6,
    title: "Arcade",
    key: "egame",
    titlebn: "স্পোর্টসবুক",
    array: [
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "arcade",
        // gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "JDB",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "arcade",
        // gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "FC",
      },
      {
        image: spribe,
        title: "SPRIBE",
        type: "SPRIBE",
        key: "arcade",
        // gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "SPRIBE",
        casinoType: "",
      },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "arcade",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-020",
      // },
      
      // {
      //   image: esport,
      //   title: "E1sports",
      //   type: "e1sports",
      //   key: "esports",
      //   gameTypeCheck: "platForm",
      //   gameType: "EGAME",
      //   platForm: "FC",
      // },
      // {
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "arcade",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      // {
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "arcade",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType:"JDB-SLOT-072"
      // },
      // {
      //   image: pp,
      //   title: "PP",
      //   type: "pp",
      //   key: "egame",
      //   gameTypeCheck: "platForm",
      //   gameType: "egame",
      //   platForm: "PP",
      // },
      
      // {
      //   image: providernextspin,
      //   title: "NEXTSPIN",
      //   type: "FastSpin",
      //   key: "arcade",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType:"JDB-SLOT-032"
      // },
      // {
      //   image: yl,
      //   title: "YL",
      //   type: "yl",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "EGAME",
      //   platForm: "YL",
      // },
      // {
      //   image: sg,
      //   title: "Spade",
      //   type: "spade",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "EGAME",
      //   platForm: "SPADE",
      // },
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      
    ],
  },
  {
    id: 7,
    title: "Lottery",
    key: "lottery",
    titlebn: "খেলাধুলা",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "lottery",
        // gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JILI",
      },
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "lottery",
        // gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },
       {
        image: joker,
        title: "Joker",
        type: "Joker",
        key: "lottery",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JOKER",
        casinoType: "",
      },
      // {
      //   image: iconSportbook,
      //   title: "SABAVIRTUAL",
      //   type: "SABAVIRTUAL",
      //   key: "lottery",
      //   // gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "",
      //   casinoType: "",
      // },
      // {
      //   image: number,
      //   title: "Money",
      //   type: "number",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JILI",
      //   casinoType:"JILI-SLOT-029"
      // },
      
      // {
      //   image: cashRocket,
      //   title: "Rocket",
      //   type: "rocket",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType:"KM-TABLE-041"
      // },

      
     
      
      {
        image: yesbingo,
        title: "YellowBat",
        type: "yesbingo",
        key: "lottery",
        // gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YESBINGO",
      },
      
    ],
  },
  {
    id: 7,
    title: "Cock Fight",
    key: "cockfight",
    titlebn: "খেলাধুলা",
    array: [
     
      
      {
        image: cockfightIconCasino,
        title: "CockFIGHT",
        type: "cockfight",
        key: "cockfight",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "SV388",
        gameCode:"SV-LIVE-001"
      }
      
    ],
  },
];


export const validationRules = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
  newPass: /^[a-zA-Z0-9]{8,15}$/,
  newPassMessage:
    "Password must contain uppercase and lowercase characters, numbers and must be minimum 8 character long (without special character).",
  passwordMessage:
    "Password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.",
  characters: /^[a-zA-Z_ ]*$/,
  numbers: /^[0-9]*$/,
  removeWhitespace: /^[a-zA-Z0-9]+$/,
  numberNew: /^[0-9]*$/,
  numberWithDot: /^\d*(\.\d{0,10})?$/,
};
export const preventMaxInput = (e) => {
  e.target.value = e.target.value.trimStart();
  e.target.value = e.target.value.replace(/  +/g, " ");
};
export const AmountArray = [
  { id: 1, amount: 100 },
  { id: 2, amount: 500 },
  { id: 3, amount: 1000 },
  { id: 4, amount: 5000 },
  { id: 5, amount: 1000 },
  { id:6, amount: 15000 },
  { id: 7, amount: 20000 },
  { id: 8, amount: 25000 },
];
export const bottomNavigation = [
  {
    id: "1",
    menu: "Home",
    icon: HomeIcon,
    link: "/",
  },
  {
    id: 2,
    icon: DepositIcon,
    menu: "Deposit",
    link: "/deposit",
    titlebn: "আমানত",
  },
  {
    id: "3",
    menu: "Refer",
    icon: referralIcon,
    link: "/refer",
  },
  {
    id: 2,
    icon: withdrawIcon,
    menu: "Withdraw",
    link: "/withdraw",
    titlebn: "প্রত্যাহার করুন",
  },
  {
    id: 5,
    icon: AccountIcon,
    menu: "Account",
    link: "/myaccount",
    titlebn: "হিসাব",
  },
 
];
export const premiumCategoryArray = [
  { type: 1, name: "Popular" },
  { type: 3, name: "Match" },
  { type: 5, name: "Innings" },
  { type: 10, name: "Over" },
  { type: 2, name: "Batsman" },
  { type: 6, name: "Partnership" },
  { type: 7, name: "Group" },
  { type: 9, name: "Range" },
  { type: 11, name: "Head-to-Head" },
  { type: 8, name: "Player" },
];
export const categoryArray = [
  { type: 1, name: "Market" },
  { type: 3, name: "Bookmakers" },
  { type: 5, name: "ManualOdds" },
  { type: 10, name: "Win Toss" },
  { type: 2, name: "Session" },
  { type: 6, name: "Over by Over Session Market" },
  { type: 7, name: "Player" },
  { type: 9, name: "Wicket" },
  { type: 11, name: "Bowler Session" },
  { type: 8, name: "Last Digit ODD" },
  { type: 4, name: "LineMarket" },
  { type: 14, name: "Premium ODDs" },
];
