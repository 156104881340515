import { FaWallet, FaRegUser } from "react-icons/fa";
import { BiWalletAlt, BiTransfer } from "react-icons/bi";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { CgKeyhole } from "react-icons/cg";
import { FaRegClock } from "react-icons/fa";
import { TbTransferIn } from "react-icons/tb";
import { IoMdSettings } from "react-icons/io";
import { MdOutlineAccountBalance } from "react-icons/md";
import banner1 from "../assets/images/banner-1.webp";
import banner2 from "../assets/images/banner-3.webp";
import banner3 from "../assets/images/banner-3.webp";

export const sidebarData = [
  {
    firstLevel: [,
      // {id: 1,menu: "Upline Whatsapp Number",icon: <MdOutlineWhatsapp />,link: "/upline-whatsapp-number"},
      {
        id: 2,
        menu: "Balance Overview",
        icon: <FaWallet />,
        link: "/balance-overview",
        menubn: "ব্যালেন্স ওভারভিউ",
      },
      {
        id: 3,
        menu: "Account Statement",
        icon: <MdOutlineAccountBalance />,
        link: "/account-statement",
        menubn: "অ্যাকাউন্ট বিবৃতি",
      },
      {
        id: 4,
        menu: "Current Bets",
        icon: <BiWalletAlt />,
        link: "/current-bets",
      },
      {
        id: 5,
        menu: "Bets History",
        icon: <FaRegClock />,
        link: "/bets-history",
      },
      {
        id: 6,
        menu: "Profit & Loss",
        icon: <AiOutlineDollarCircle />,
        link: "/profit-and-loss",
        menubn: "লাভ ক্ষতি",
      },
      {
        id: 7,
        menu: "Active Log",
        icon: <CgKeyhole />,
        link: "/active-log",
        menubn: "সক্রিয় লগ",
      },
      {
        id: 8,
        menu: "My Profile",
        icon: <FaRegUser />,
        link: "/my-profile",
        menubn: "আমার প্রোফাইল",
      },
    ],
  },

  {
    secondLevel: [
      {
        id: 8,
        menu: "P2P Transfer",
        icon: <BiTransfer />,
        link: "/p2p-transfer",
      },
      {
        id: 9,
        menu: "P2P Transfer log",
        icon: <TbTransferIn />,
        link: "/p2p-transfer-log",
      },
    ],
  },

  {
    thirdLevel: [
      { id: 10, menu: "Setting", icon: <IoMdSettings />, link: "/setting" },
    ],
  },
];


export const gamesLiveArray = [

  // -------------------live EVO Start-----------------
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-369.png",
    name: "Lightning Storm",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-369",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-205.png",
    name: "Baccarat",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-205",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-206.png",
    name: "American Roulette",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-206",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-033.png",
    name: "American Roulette",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-033",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-045.png",
    name: "Football Studio",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-045",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-027.png",
    name: "Texas Hold'em Bonus Poker",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-027",
  },
  {
    img: "../assets/images/live/evo/top_games.png",
    name: "Top Games",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-027",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-009.png",
    name: "Triple Card Poker",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-009",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-009.png",
    name: "Triple Card Poker",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-009",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-032.png",
    name: "Ultimate Texas Holdem",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-032",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-024.png",
    name: "Caribbean Stud Poker",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-024",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-363.png",
    name: "Balloon Race",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-363",
  },

  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-117.png",
    name: "Blackjack Platinum VIP",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-117",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-032(1).png",
    name: "Extreme Texas Holdem",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-032",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-005.png",
    name: "Sic Bo",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-005",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-002.png",
    name: "Blackjack",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-002",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-227.png",
    name: "Poker",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-227",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-021.png",
    name: "2 Hand Casino Hold'e",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-021",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-015.png",
    name: "2 Hand Casino Hold'em",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-015",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-410.png",
    name: "Always 8 Baccarat",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-410",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-020.png",
    name: "American Roulette",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-020",
  },

  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-170.png",
    name: "Auto-Roulette",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-170",
  },

  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-171.png",
    name: "Auto-Roulette VIP",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-171",
  },

  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-172.png",
    name: "Bac Bo",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-172",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-118.png",
    name: "Blackjack Diamond VIP",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-118",
  },
  
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-116.png",
    name: "Blackjack Grand VIP",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-116",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-231.png",
    name: "Bonsai Speed Baccarat A",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-231",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-232.png",
    name: "Bonsai Speed Baccarat B",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-232",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-233.png",
    name: "Bonsai Speed Baccarat C",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-233",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-175.png",
    name: "Cash Or Crash",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-175",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-006.png",
    name: "Crazy Time",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-114.png",
    name: "Crazy Time A",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-114",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-176.png",
    name: "Dead or Alive: Saloon",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-176",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-035.png",
    name: "Dream Catcher",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-035",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-168.png",
    name: "Extra Chili Epic Spins",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-168",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-178.png",
    name: "Fan Tan",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-178",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-025.png",
    name: "Deal or No Deal",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-025",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-010.png",
    name: "Mega Ball",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-010",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-183.png",
    name: "Funky Time",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-183",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-376.png",
    name: "Lightning Ball",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-376",
  },{
    img: "../assets/images/live/evo/EVOLUTION-LIVE-018.png",
    name: "MONOPOLY Big Baller",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-018",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-007.png",
    name: "MONOPOLY Live",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-007",
  },
  {
    img: "../assets/images/live/evo/EVOLUTION-LIVE-257.png",
    name: "Red Door Roulette",
    category:"live",
    type:"EVO",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-257",
  },
   // -------------------live EVO END-----------------

   // -------------------live SEXY Start-----------------
  {
    img: "../assets/images/live/sexy/MX-LIVE-002.png",
    name: "Sexy Baccarat",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-002",
    table:1
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-013.png",
    name: "1 Day Teen Patti",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-011",
    table:'81'
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-011.png",
    name: "2020 Teen Patti",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-011",
    table:"C04"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-011_SEXY_81.png",
    name: "2020 Teen Patti-SEXY hall-Table1",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-011",
    table:"C05"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-012.png",
    name: "Andar Bahar",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-011",
    table:"C06"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-011_SEXY_81.png",
    name: "SEXY Andar Bahar-SEXY hall-Table1",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-012",
    table:"101"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-001.png",
    name: "Baccarat Classic",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-001_SEXY_1.png",
    name: "Baccarat Classic-SEXY hall-Table1",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    table:"1"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-001_SEXY_2.jpg",
    name: "Baccarat Classic-SEXY hall-Table2",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    table:"2"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-001_SEXY_3.jpg",
    name: "Baccarat Classic-SEXY hall-Table3",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    table:"3"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-001_SEXY_4.jpg",
    name: "Baccarat Classic-SEXY hall-Table4",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    table:"4"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-001_SEXY_5.jpg",
    name: "Baccarat Classic-SEXY hall-Table1",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-005",
    table:"1"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-001_SEXY_6.jpg",
    name: "Baccarat Classic-SEXY hall-Table1",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    table:"6"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-006.png",
    name: "Dragon Tiger",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-006_SEXY_31.png",
    name: "Dragon Tiger-SEXY hall-Table1",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    table:"31"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-006_SEXY_32.jpg",
    name: "Dragon Tiger-SEXY hall-Table2",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    table:"32"
  },

  {
    img: "../assets/images/live/sexy/MX-LIVE-016.png",
    name: "Extra Sicbo",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-016",
    
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-009.png",
    name: "Roulette",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-009",
    
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-009_SEXY_71.png",
    name: "Roulette-SEXY hall-Table1",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-009",
    table:"71"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-017.png",
    name: "Sedie",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-017",
    
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-016_SEXY.png",
    name: "Sic Bo-SEXY hall",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-016",
     table:"131"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-016_SEXY_51.png",
    name: "Sic Bo-SEXY hall-Table1",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-016",
     table:"132"
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-015.png",
    name: "Thai Fish Prawn Crab",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-015",
    
  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-015_SEXY_126.png",
    name: "Fish Prawn Crab Table1",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-015",
    table:"126"

  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-014.png",
    name: "Thai Hi Lo",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-014",
  

  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-014.png",
    name: "Thai Hi Lo Sexy Hall",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-014",
   table:"122"

  },
  {
    img: "../assets/images/live/sexy/MX-LIVE-014_SEXY_121.png",
    name: "Thai Hi Lo",
    category:"live",
    type:"SEXY",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-014",
    table:"121"
  },
   // -------------------live SEXY END-----------------

// -------------------live PP START-----------------
{
  img: "../assets/images/live/pp/PP-LIVE-043.png",
  name: "Andar Bahar",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-043",
},
{
  img: "../assets/images/live/pp/PP-LIVE-075.png",
  name: "Auto Mega Roulette",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-075",
},
{
  img: "../assets/images/live/pp/PP-LIVE-026.png",
  name: "Auto-Roulette 1",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-026",
},
{
  img: "../assets/images/live/pp/PP-LIVE-001.png",
  name: "Baccarat 1",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-001",
},
{
  img: "../assets/images/live/pp/PP-LIVE-010.jpg",
  name: "Baccarat 11",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-010",
},
{
  img: "../assets/images/live/pp/PP-LIVE-002.jpg",
  name: "Baccarat 2",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-002",
},
{
  img: "../assets/images/live/pp/PP-LIVE-004.jpg",
  name: "Baccarat 5",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-004",
},
{
  img: "../assets/images/live/pp/PP-LIVE-005.jpg",
  name: "Baccarat 6",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-005",
},
{
  img: "../assets/images/live/pp/PP-LIVE-006.jpg",
  name: "Baccarat 7",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-006",
},
{
  img: "../assets/images/live/pp/PP-LIVE-007.jpg",
  name: "Baccarat 8",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-007",
},
{
  img: "../assets/images/live/pp/PP-LIVE-052.jpg",
  name: "Baccarat 9",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-052",
},
{
  img: "../assets/images/live/pp/PP-LIVE-143.png",
  name: "Bet Behind Pro Blackjack ",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-143",
},
{
  img: "../assets/images/live/pp/PP-LIVE-120.png",
  name: "BlackjackX 21 - Ruby ",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-120",
},
{
  img: "../assets/images/live/pp/PP-LIVE-121.png",
  name: "BlackjackX 24 - Azure",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-121",
},
{
  img: "../assets/images/live/pp/PP-LIVE-122.png",
  name: "BlackjackX 25 - Azure ",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-122",
},
{
  img: "../assets/images/live/pp/PP-LIVE-144.png",
  name: "Brazilian ONE Blackjack ",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-144",
},
{
  img: "../assets/images/live/pp/PP-LIVE-074.png",
  name: "Brazilian Roulette ",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-074",
},
{
  img: "../assets/images/live/pp/PP-LIVE-080.png",
  name: "Chinese Speed Baccarat 1 ",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-080",
},
{
  img: "../assets/images/live/pp/PP-LIVE-114.png",
  name: "Chinese Speed Baccarat 2 ",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-114",
},
{
  img: "../assets/images/live/pp/PP-LIVE-139.png",
  name: "Chinese Speed Baccarat 3 ",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-139",
},
{
  img: "../assets/images/live/pp/PP-LIVE-042.png",
  name: "Dragon Tiger",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-042",
},

{
  img: "../assets/images/live/pp/PP-LIVE-047.png",
  name: "Fortune 6 Baccarat",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-047",
},
{
  img: "../assets/images/live/pp/PP-LIVE-047.png",
  name: "Fortune 6 Baccarat",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-047",
},
{
  img: "../assets/images/live/pp/pp_loby.jpeg",
  name: "Fortune 6 Baccarat",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "",
},
{
  img: "../assets/images/live/pp/PP-LIVE-031.png",
  name: "German Roulette",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-031",
},
{
  img: "../assets/images/live/pp/PP-LIVE-072.png",
  name: "Indonesian Speed Baccarat 1",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-072",
},
{
  img: "../assets/images/live/pp/PP-LIVE-146.png",
  name: "Indonesian Roulette",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-146",
},
{
  img: "../assets/images/live/pp/PP-LIVE-118.png",
  name: "Japanese Speed Baccarat 1",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-118",
},
{
  img: "../assets/images/live/pp/PP-LIVE-119.png",
  name: "Japanese Speed Baccarat 2",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-119",
},
{
  img: "../assets/images/live/pp/PP-LIVE-099.png",
  name: "Japanese Speed Baccarat 3",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-099",
},
{
  img: "../assets/images/live/pp/PP-LIVE-073.png",
  name: "Korean Roulette",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-073",
},
{
  img: "../assets/images/live/pp/PP-LIVE-056.png",
  name: "Korean Speed Baccarat 1",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-056",
},
{
  img: "../assets/images/live/pp/PP-LIVE-057.png",
  name: "Korean Speed Baccarat 2",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-057",
},
{
  img: "../assets/images/live/pp/PP-LIVE-070.png",
  name: "Korean Speed Baccarat 3",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-070",
},
{
  img: "../assets/images/live/pp/PP-LIVE-071.png",
  name: "Korean Speed Baccarat 4",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-071",
},
{
  img: "../assets/images/live/pp/PP-LIVE-117.png",
  name: "Korean Speed Baccarat 5",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-117",
},
{
  img: "../assets/images/live/pp/PP-LIVE-113.png",
  name: "Korean Speed Baccarat 6",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-113",
},
{
  img: "../assets/images/live/pp/PP-LIVE-077.png",
  name: "Lucky 6 Roulette",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-077",
},
{
  img: "../assets/images/live/pp/PP-LIVE-061.png",
  name: "Mega Baccarat",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-061",
},
{
  img: "../assets/images/live/pp/PP-LIVE-039.png",
  name: "Mega Roulette",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-039",
},
{
  img: "../assets/images/live/pp/PP-LIVE-141.png",
  name: "Mega Roulette - Brazilian",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-141",
},
{
  img: "../assets/images/live/pp/PP-LIVE-140.png",
  name: "Mega Sic Bac",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-140",
},
{
  img: "../assets/images/live/pp/PP-LIVE-025.png",
  name: "Mega Sic Bo",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-025",
},
{
  img: "../assets/images/live/pp/PP-LIVE-038.png",
  name: "Mega Wheel",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-038",
},
{
  img: "../assets/images/live/pp/PP-LIVE-116.png",
  name: "Punto Banco Italia Tricolore",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-116",
},
{
  img: "../assets/images/live/pp/PP-LIVE-079.png",
  name: "Sic Bo",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-079",
},
{
  img: "../assets/images/live/pp/PP-LIVE-062.png",
  name: "Snakes & Ladders Live",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-062",
},
{
  img: "../assets/images/live/pp/PP-LIVE-044.png",
  name: "Sweet Bonanza Candyland",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-044",
},
{
  img: "../assets/images/live/pp/PP-LIVE-076.png",
  name: "Vegas Ball Bonanza",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-076",
},
{
  img: "../assets/images/live/pp/PP-LIVE-068.png",
  name: "Vietnamese Roulette",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-068",
},
{
  img: "../assets/images/live/pp/PP-LIVE-115.png",
  name: "Vietnamese Speed Baccarat 1",
  category:"live",
  type:"PP",
  platForm: "PP",
  gameType: "LIVE",
  casinoType: "PP-LIVE-115",
},
// -------------------live PP END-----------------
// -------------------live PT START-----------------

{
  img: "../assets/images/live/pt/lobby.jpg",
  name: "Lobby",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "",
},
{
  img: "../assets/images/live/pt/PT-LIVE-016.png",
  name: "3 Card Brag",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-016",
},
{
  img: "../assets/images/live/pt/PT-LIVE-011.png",
  name: "Adventures Beyond Wonderland",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-011",
},
{
  img: "../assets/images/live/pt/PT-LIVE-020.jpg",
  name: "Age Of the code",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-020",
},
{
  img: "../assets/images/live/pt/PT-LIVE-006.png",
  name: "Alexandrite Blackjack",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-006",
},
{
  img: "../assets/images/live/pt/PT-LIVE-007.png",
  name: "All Bets Blackjack",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-007",
},
{
  img: "../assets/images/live/pt/PT-LIVE-126.png",
  name: "All Bets Blackjack Brasileira",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-126",
},
{
  img: "../assets/images/live/pt/PT-LIVE-021.png",
  name: "American Roulette",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-021",
},
{
  img: "../assets/images/live/pt/PT-LIVE-004.png",
  name: "Andar Bahar Live",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-004",
},
{
  img: "../assets/images/live/pt/PT-LIVE-078.jpg",
  name: "Arabic Roulette",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-078",
},
{
  img: "../assets/images/live/pt/PT-LIVE-100.jpg",
  name: "Auto Roulette 2",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-100",
},
{
  img: "../assets/images/live/pt/PT-LIVE-001.png",
  name: "Baccarat",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-001",
},
{
  img: "../assets/images/live/pt/PT-LIVE-097.jpg",
  name: "Baccarat Brasileira",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-097",
},{
  img: "../assets/images/live/pt/PT-LIVE-098.jpg",
  name: "Baccarat Brasileira NC",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-098",
},
{
  img: "../assets/images/live/pt/PT-LIVE-075.jpg",
  name: "Baccarat NC",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-075",
},
{
  img: "../assets/images/live/pt/PT-LIVE-115.png",
  name: "Baccarat Soirée",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-115",
},
{
  img: "../assets/images/live/pt/PT-LIVE-129.png",
  name: "Baccarat Soirée NC",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-129",
},

{
  img: "../assets/images/live/pt/PT-LIVE-030.png",
  name: "Bet On Dragon Tiger Live",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-030",
},
{
  img: "../assets/images/live/pt/PT-LIVE-090.jpg",
  name: "Big Bad Wolf Live",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-090",
},
{
  img: "../assets/images/live/pt/PT-LIVE-127.png",
  name: "Blackjack 10",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-127",
},
{
  img: "../assets/images/live/pt/PT-LIVE-101.jpg",
  name: "Buffalo Blitz España",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-101",
},
{
  img: "../assets/images/live/pt/PT-LIVE-002.png",
  name: "Dragon Tiger",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-002",
},
{
  img: "../assets/images/live/pt/PT-LIVE-025.png",
  name: "French Roulette",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-025",
},
{
  img: "../assets/images/live/pt/PT-LIVE-013.png",
  name: "God Of strorms",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-013",
},
{
  img: "../assets/images/live/pt/PT-LIVE-014.png",
  name: "Hi-Lo",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-014",
},
{
  img: "../assets/images/live/pt/PT-LIVE-130.png",
  name: "Lucky Ball Roulette Live",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-130",
},
{
  img: "../assets/images/live/pt/PT-LIVE-088.jpg",
  name: "Spin a Win Wild Live",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-088",
},
{
  img: "../assets/images/live/pt/PT-LIVE-086.jpg",
  name: "The Greatest Cards Show",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-086",
},
{
  img: "../assets/images/live/pt/PT-LIVE-058.jpg",
  name: "Speed Auto Roulette",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-058",
},
{
  img: "../assets/images/live/pt/PT-LIVE-106.jpg",
  name: "Mega Fire Blaze Blackjack Live",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-106",
},
{
  img: "../assets/images/live/pt/PT-LIVE-026.png",
  name: "Quantum Auto Roulette",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-026",
},
{
  img: "../assets/images/live/pt/PT-LIVE-003.png",
  name: "SicBo Deluxe",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-003",
},
{
  img: "../assets/images/live/pt/PT-LIVE-109.png",
  name: "Speed Baccarat",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-109",
},
{
  img: "../assets/images/live/pt/PT-LIVE-015.png",
  name: "Spin a Win",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-015",
},
{
  img: "../assets/images/live/pt/PT-LIVE-102.jpg",
  name: "x1000 Quantum Roulette",
  category:"live",
  type:"PT",
  platForm: "PT",
  gameType: "LIVE",
  casinoType: "PT-LIVE-102",
},
// -------------------live PT END-----------------


// -------------------LIVE HOTROAD START-----------------
{
  img: "../assets/images/live/hotroad/HOTROAD-LIVE-001.png",
  name: "BACCARAT",
  category:"live",
  type:"HOTROAD",
  platForm: "HOTROAD",
  gameType: "LIVE",
  casinoType: "HOTROAD-LIVE-001",
},
{
  img: "../assets/images/live/hotroad/HOTROAD-LIVE-003.png",
  name: "Dragon Tiger",
  category:"live",
  type:"HOTROAD",
  platForm: "HOTROAD",
  gameType: "LIVE",
  casinoType: "HOTROAD-LIVE-003",
},
{
  img: "../assets/images/live/hotroad/HOTROAD-LIVE-008.png",
  name: "HOT 7up 7down",
  category:"live",
  type:"HOTROAD",
  platForm: "HOTROAD",
  gameType: "LIVE",
  casinoType: "HOTROAD-LIVE-008",
},
{
  img: "../assets/images/live/hotroad/HOTROAD-LIVE-007.png",
  name: "HOT Baccarat",
  category:"live",
  type:"HOTROAD",
  platForm: "HOTROAD",
  gameType: "LIVE",
  casinoType: "HOTROAD-LIVE-007",
},
{
  img: "../assets/images/live/hotroad/HOTROAD-LIVE-005.png",
  name: "HOT COLOR GAME",
  category:"live",
  type:"HOTROAD",
  platForm: "HOTROAD",
  gameType: "LIVE",
  casinoType: "HOTROAD-LIVE-005",
},
{
  img: "../assets/images/live/hotroad/HOTROAD-LIVE-004.png",
  name: "HOT ROULETTE",
  category:"live",
  type:"HOTROAD",
  platForm: "HOTROAD",
  gameType: "LIVE",
  casinoType: "HOTROAD-LIVE-004",
},

{
  img: "../assets/images/live/hotroad/HOTROAD-LIVE-006.png",
  name: "HOT SICBO",
  category:"live",
  type:"HOTROAD",
  platForm: "HOTROAD",
  gameType: "LIVE",
  casinoType: "HOTROAD-LIVE-006",
},
{
  img: "../assets/images/live/hotroad/HOTROAD-LIVE-009.png",
  name: "HOT Thai HI-LO",
  category:"live",
  type:"HOTROAD",
  platForm: "HOTROAD",
  gameType: "LIVE",
  casinoType: "HOTROAD-LIVE-009",
},
{
  img: "../assets/images/live/hotroad/HOTROAD-LIVE-002.png",
  name: "Roulette",
  category:"live",
  type:"HOTROAD",
  platForm: "HOTROAD",
  gameType: "LIVE",
  casinoType: "HOTROAD-LIVE-002",
},



// -------------------LIVE HOTROAD END-----------------

// LIVE BG Start -----
{
  img: "../assets/images/live/bg/BG-LIVE-001.webp",
  name: "Live Casino BG",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-001",
  
},
{
  img: "../assets/images/live/bg/BG-LIVE-002.webp",
  name: "Baccarat",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-002",
 
},
{
  img: "../assets/images/live/bg/BG-LIVE-003.webp",
  name: "Roulette Awc",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-003",
 
},
{
  img: "../assets/images/live/bg/BG-LIVE-004.webp",
  name: "Sicbo Awc",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-004",
 
},
{
  img: "../assets/images/live/bg/BG-LIVE-005.webp",
  name: "Dragon tiger",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-005",
  
},
{
  img: "../assets/images/live/bg/BG-LIVE-006.webp",
  name: "Speed Baccarat",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-006",
  
},
{
  img: "../assets/images/live/bg/BG-LIVE-007.webp",
  name: "Mi Card Baccarat",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-007",
  
},
{
  img: "../assets/images/live/bg/BG-LIVE-008.webp",
  name: "Full Color Baccarat",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-008",
 
},
{
  img: "../assets/images/live/bg/BG-LIVE-009.webp",
  name: "BullBull",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-009",
 
},
{
  img: "../assets/images/live/bg/BG-LIVE-010.webp",
  name: "Win Three Cards",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-010",
 
},
{
  img: "../assets/images/live/bg/BG-LIVE-011.webp",
  name: "RedBlack",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-011",
  
},
{
  img: "../assets/images/live/bg/BG-LIVE-012.webp",
  name: "SpeedSicBo",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-012",

},
{
  img: "../assets/images/live/bg/BG-LIVE-013.webp",
  name: "SeDie",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-013",
 
},
{
  img: "../assets/images/live/bg/BG-LIVE-014.webp",
  name: "CasinoWar",
  category: "live",
  type: "BG",
  platForm: "BG",
  gameType: "LIVE",
  casinoType: "BG-LIVE-014",
  
},
// LIVE BG END -----


// -------------------slot JILI START-----------------

  {
    img: "../assets/images/slot/jili/JILI-SLOT-027.png",
    name: "Super Ace",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-027",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-029.png",
    name: "Money Coming",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-029",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-031.png",
    name: "Boxing King",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-031",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-076.png",
    name: "Fortune Gems 2",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-076",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-043.png",
    name: "Fortune Gems",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-043",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-102.png",
    name: "Super Ace Deluxe",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-102",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-014.png",
    name: "Crazy777",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-014",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-049.png",
    name: "Alibaba",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-049",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-023.png",
    name: "Golden Bank",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-023",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-015.png",
    name: "Bubble Beauty",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-015",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-042.png",
    name: "Golden Empire",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-042",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-026.png",
    name: "Charge Buffalo",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-026",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-028.png",
    name: "Jungle King",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-028",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-075.png",
    name: "Wild Ace",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-075",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-051.png",
    name: "Mega Ace",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-051",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-087.png",
    name: "Fortune Monkey",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-087",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-013.png",
    name: "SevenSevenSeven",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-013",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-086.png",
    name: "Bangla Beauty",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-086",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-100.png",
    name: "Jackpot Joker",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-100",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-101.png",
    name: "3 Coin Treasures",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-101",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-109.png",
    name: "3 Lucky Piggy",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-109",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-106.png",
    name: "3 Pot Dragons ",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-106",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-048.png",
    name: "Agent Ace ",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-048",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-083.png",
    name: "Aztec Priestess",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-083",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-016.png",
    name: "Bao boon chin",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-016",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-059.png",
    name: "Bone Fortune",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-059",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-062.png",
    name: "Bonus Hunter",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-062",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-053.png",
    name: "Book of Gold",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-053",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-020.png",
    name: "Candy Baby",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-020",
  },

  {
    img: "../assets/images/slot/jili/JILI-SLOT-093.png",
    name: "Charge Buffalo Ascent ",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-093",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-003.png",
    name: "Chin Shi Huang ",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-003",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-017.png",
    name: "Crazy FaFaFa",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-017",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-045.png",
    name: "Crazy Hunter",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-045",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-099.png",
    name: "Crazy Hunter 2",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-099",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-054.png",
    name: "Crazy Pusher",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-054",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-078.png",
    name: "Cricket King 18",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-078",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-082.png",
    name: "Cricket Sah 75",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-082",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-088.png",
    name: "Dabanggg",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-088",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-096.png",
    name: "Fortune Gems 3",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-096",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-019.png",
    name: "FortunePig",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-019",
  },

  {
    img: "../assets/images/slot/jili/JILI-SLOT-111.png",
    name: "Fruity Wheel",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-111",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-010.png",
    name: "God Of Martial",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-010",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-057.png",
    name: "Gold Rush",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-057",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-116.png",
    name: "Golden Bank 2",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-116",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-074.png",
    name: "Golden Joker",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-074",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-030.png",
    name: "Golden Queen",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-030",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-030.png",
    name: "Golden Queen",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-030",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-056.png",
    name: "Happy Taxi",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-056",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-002.png",
    name: "Hot Chilli",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-002",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-006.png",
    name: "Lucky Ball",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-006",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-037.png",
    name: "Lucky Coming",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-037",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-060.png",
    name: "JILI CAISHEN",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-060",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-007.png",
    name: "Hyper Burst",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-007",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-046.png",
    name: "Magic Lamp",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-046",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-107.png",
    name: "Money Coming Expand Bets",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-107",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-090.png",
    name: "Night City",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-090",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-118.png",
    name: "Super Ace Joker",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-118",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-079.png",
    name: "Sweet Land",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-079",
  },

  {
    img: "../assets/images/slot/jili/JILI-SLOT-084.png",
    name: "Trial of Phoenix",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-084",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-004.png",
    name: "War Of Dragons",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-004",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-072.png",
    name: "Wild Racer",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-072",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-095.png",
    name: "Witches Night",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-095",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-063.png",
    name: "World Cup",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-063",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-018.png",
    name: "XiYangYang",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-018",
  },
  {
    img: "../assets/images/slot/jili/JILI-SLOT-097.png",
    name: "Zeus",
    category:"slot",
    type:"JILI",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-097",
  },
    // -------------------slot JILI END-----------------

 // -------------------slot PG START-----------------
 {
  img: "../assets/images/slot/pg/PG-SLOT-049.png",
  name: "Bikini Paradise",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-049",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-047.png",
  name: "Caishen Wins",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-047",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-059.png",
  name: "Circus Delight",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-059",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-030.png",
  name: "Double Fortune",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-030",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-016.png",
  name: "Dragon Legend",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-016",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-035.png",
  name: "Dragon Tiger Luck",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-035",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-028.png",
  name: "Emperors Favour",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-028",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-036.png",
  name: "Flirting Scholar",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-036",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-003.png",
  name: "Fortune Gods",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-003",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-043.png",
  name: "Fortune Mouse",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-043",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-067.png",
  name: "Galactic Gems",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-067",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-002.png",
  name: "Gem Saviour",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-002",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-045.png",
  name: "Gem Saviour Conquest",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-045",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-022.png",
  name: "Gem Saviour Sword",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-022",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-057.png",
  name: "Genie's 3 Wishes",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-057",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-017.png",
  name: "Hip Hop Panda",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-017",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-001.png",
  name: "Honey Trap of Diao Chan",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-001",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-009.png",
  name: "Hood vs Wolf",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-009",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-015.png",
  name: "Hotpot",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-015",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-066.png",
  name: "Jack Frost's Winter",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-066",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-064.png",
  name: "Jewels of Prosperity",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-064",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-037.png",
  name: "Journey To The Wealth",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-037",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-024.png",
  name: "Jungle Delight",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-024",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-018.png",
  name: "Legend of Hou Yi",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-018",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-038.png",
  name: "Leprechaun Riches",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-038",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-041.png",
  name: "Mahjong Ways",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-041",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-050.png",
  name: "Mahjong Ways 2",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-050",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-006.png",
  name: "Medusa",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-006",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-005.png",
  name: "Medusa2",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-005",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-019.png",
  name: "Mr. Hallow-Win",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-019",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-039.png",
  name: "Muay Thai Champion",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-039",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-040.png",
  name: "Ninja vs Samurai",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-040",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-053.png",
  name: "Phoenix Rises",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-053",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-023.png",
  name: "Piggy Gold",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-023",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-012.png",
  name: "Plushie Frenzy",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-012",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-020.png",
  name: "Prosperity Lion",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-020",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-044.png",
  name: "Reel Love",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-044",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-021.png",
  name: "Santas Gift Rush",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-021",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-061.png",
  name: "Secret of Cleopatra",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-061",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-046.png",
  name: "Shaolin Soccer",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-046",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-025.png",
  name: "Symbols of Egypt",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-025",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-060.png",
  name: "Thai River Wonders",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-060",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-032.png",
  name: "The Great Icescape",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-032",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-013.png",
  name: "Tree of Fortune",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-013",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-062.png",
  name: "Vampire's Charm",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-062",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-105.png",
  name: "Wild Bounty Showdown",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-105",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-055.png",
  name: "Wild Fireworks",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-055",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-011.png",
  name: "Win Win Won",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-011",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-058.png",
  name: "Treasures of Aztec",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-058",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-110.png",
  name: "Asgardian Rising",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-110",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-065.png",
  name: "Lucky Neko",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-065",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-075.png",
  name: "Wild Bandito",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-075",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-112.png",
  name: "Fortune Rabbit",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-112",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-102.png",
  name: "Speed Winner",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-102",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-084.png",
  name: "Supermarket Spree",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-084",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-090.png",
  name: "Cocktail Nights",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-090",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-103.png",
  name: "Legend of Perseus",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-103",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-111.png",
  name: "Midas Fortune",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-111",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-079.png",
  name: "Jurassic Kingdom",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-079",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-131.png",
  name: "Dragon Hatch 2",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-131",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-052.png",
  name: "Ganesha Fortune",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-052",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-034.png",
  name: "Captains Bounty",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-034",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-073.png",
  name: "Majestic Treasures",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-073",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-054.png",
  name: "Majestic Treasures",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-054",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-109.png",
  name: "Diner Delights",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-109",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-042.png",
  name: "Dragon Hatch",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-042",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-063.png",
  name: "Queen of Bounty",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-063",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-048.png",
  name: "Candy Burst",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-048",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-051.png",
  name: "Egypt's Book of Mystery",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-051",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-026.png",
  name: "Ganesha Gold",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-026",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-108.png",
  name: "Alchemy Gold",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-108",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-138.png",
  name: "Anubis Wrath",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-138",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-085.png",
  name: "Buffalo Win",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-085",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-074.png",
  name: "Candy Bonanza",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-074",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-134.png",
  name: "Cash Mania",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-134",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-121.png",
  name: "Cruise Royale",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-121",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-072.png",
  name: "Crypto Gold",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-072",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-095.png",
  name: "Destiny of sun and moon",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-095",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-120.png",
  name: "Fruity Candy",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-120",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-123.png",
  name: "Gladiator's Glory",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-123",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-086.png",
  name: "Legendary Monkey King",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-086",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-101.png",
  name: "Lucky Piggy",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-101",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-128.png",
  name: "Mafia Mayhem",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-128",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-106.png",
  name: "Prosperity Fortune Tree",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-106",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-113.png",
  name: "Rave Party Fever",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-113",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-142.png",
  name: "Shark Bounty",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-142",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-125.png",
  name: "Ultimate Striker",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-125",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-126.png",
  name: "Wild Heist Cashout",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-126",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-100.png",
  name: "Win Win Fish Prawn Crab",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-100",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-144.png",
  name: "Wings of Iguazu",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-144",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-143.png",
  name: "Yakuza Honor",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-143",
},
{
  img: "../assets/images/slot/pg/PG-SLOT-139.png",
  name: "Zombie Outbreak",
  category:"slot",
  type:"PG",
  platForm: "PG",
  gameType: "SLOT",
  casinoType: "PG-SLOT-139",
},


 // -------------------slot PG END-----------------

 // -------------------slot JDB START-----------------
 {

  img: "../assets/images/slot/jdb/JDB-SLOT-106.webp",
  name: "FortuneNeko",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-106",


},
 {
  img: "../assets/images/slot/jdb/JDB-SLOT-009.png",
  name: "Beauty And The Kingdom",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-009",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-019.png",
  name: "Banana Saga",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-019",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-066.png",
  name: "Birds Party",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-066",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-036.png",
  name: "Chef Panda",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-036",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-063.png",
  name: "Daji",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-063",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-087.png",
  name: "Double Wilds",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-087",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-025.png",
  name: "Dragon",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-025",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-032.png",
  name: "Dragon Warrior",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-032",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-092.png",
  name: "Dragons Gate",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-092",
},

{
  img: "../assets/images/slot/jdb/JDB-SLOT-069.png",
  name: "Dragons World",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-069",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-010.png",
  name: "Flirting Scholar Tang",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-010",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-052.png",
  name: "Flirting Scholar Tang II",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-052",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-014.png",
  name: "Formosa Bear",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-014",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-072.png",
  name: "Fortune Treasure",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-072",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-053.png",
  name: "Fortune Horse",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-053",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-017.png",
  name: "Four Treasures",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-017",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-076.png",
  name: "Funky King Kong",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-076",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-067.png",
  name: "Go Lai Fu",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-067",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-030.png",
  name: "Guan Gong",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-030",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-091.png",
  name: "Jungle Jungle",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-091",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-094.png",
  name: "Kong",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-094",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-044.png",
  name: "Legendary 5",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-044",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-013.png",
  name: "Llama Adventure",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-013",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-045.png",
  name: "Lucky Dragons",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-045",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-046.png",
  name: "Lucky Lion",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-046",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-004.png",
  name: "Lucky Miner",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-004",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-027.png",
  name: "Monkey King",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-027",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-028.png",
  name: "Moonlight Treasure",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-028",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-048.png",
  name: "Napoleon",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-048",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-123.png",
  name: "Piggy Bank",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-123",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-073.png",
  name: "Pirate Treasure",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-073",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-099.png",
  name: "Maya Gold Crazy",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-099",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-110.png",
  name: "Elemental Link Fire",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-110",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-124.png",
  name: "Magic Ace",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-124",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-041.png",
  name: "Lucky Seven",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-041",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-093.png",
  name: "Lucky Diamond",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-093",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-071.png",
  name: "Egypt Treasure",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-071",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-108.png",
  name: "Moneybags Man 2",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-108",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-120.png",
  name: "Pop Pop Candy",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-120",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-065.png",
  name: "Lucky Racing",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-065",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-066.png",
  name: "Birds Party",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-066",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-113.png",
  name: "CaiShen Coming",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-113",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-128.png",
  name: "Fortune Jewel",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-128",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-106.png",
  name: "Fortune Neko",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-106",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-114.png",
  name: "Fruity Bonanza",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-114",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-115.png",
  name: "LanternWealth",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-115",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-127.png",
  name: "Magic Ace WILD LOCK",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-127",
},
{
  img: "../assets/images/slot/jdb/JDB-SLOT-122.png",
  name: "Wealthway",
  category:"slot",
  type:"JDB",
  platForm: "JDB",
  gameType: "SLOT",
  casinoType: "JDB-SLOT-122",
},

 // -------------------slot JDB END-----------------

 // -------------------slot FASTSPIN START-----------------
 {
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-001.png",
  name: "Royale House",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-001",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-032.png",
  name: "The Great Safari",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-032",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-022.png",
  name: "The Maya Myth",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-022",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-013.png",
  name: "Fiery Lava",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-013",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-040.png",
  name: "Money Empire",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-040",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-012.png",
  name: "Fruits Mania",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-012",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-002.png",
  name: "Sexy Vegas",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-002",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-009.png",
  name: "Poker Ways",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-009",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-043.png",
  name: "Money Dragon",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-043",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-030.png",
  name: "The Nutcrackers",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-030",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-006.png",
  name: "Royal Katt",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-006",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-042.png",
  name: "Ranger Showdown",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-042",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-005.png",
  name: "Wild Wet Win",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-005",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-024.png",
  name: "Safari Blitz",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-024",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-027.png",
  name: "Ganesha Luck",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-027",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-017.png",
  name: "Space Conquest",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-017",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-036.png",
  name: "Mushroom Bandit",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-036",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-025.png",
  name: "Goddess Of Egypt",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-025",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-004.png",
  name: "Gold Rush Cowboy",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-004",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-018.png",
  name: "Loki",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-018",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-045.png",
  name: "Farm Of Fortune",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-045",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-019.png",
  name: "Fortune Lions",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-019",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-014.png",
  name: "God of Wealth",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-014",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-028.png",
  name: "Heavenly Fortunes",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-028",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-026.png",
  name: "Heavenly Fortunes 2",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-026",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-020.png",
  name: "Honey Trap",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-020",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-015.png",
  name: "Legend of Eagle",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-015",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-031.png",
  name: "Legend of Nian",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-031",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-039.png",
  name: "Leprechaun Bonanza",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-039",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-011.png",
  name: "Lucky Fortune",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-011",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-047.png",
  name: "Mahjong Princess",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-047",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-035.png",
  name: "Mighty Sevens",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-035",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-029.png",
  name: "Mining Bonanza",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-029",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-038.png",
  name: "Sweet Frenzy",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-038",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-033.png",
  name: "Tiki Rush",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-033",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-010.png",
  name: "Triple Happiness",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-010",
},
{
  img: "../assets/images/slot/fastspin/FastSpin-SLOT-041.png",
  name: "Yeti Boom",
  category:"slot",
  type:"FASTSPIN",
  platForm: "FASTSPIN",
  gameType: "SLOT",
  casinoType: "FastSpin-SLOT-041",
},


 // -------------------slot FASTSPIN END-----------------



 // -------------------SLOT FC START---------------
 {
  img: "../assets/images/slot/fc/FC-SLOT-028.png",
  name: "GOLDEN GENIE",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-028",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-029.png",
  name: "ZEUS",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-029",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-024.png",
  name: "TREASURE RAIDERS",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-024",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-026.png",
  name: "CRAZY BUFFALO",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-026",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-023.png",
  name: "GRAND BLUE",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-023",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-034.png",
  name: "LEGEND OF INCA",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-034",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-013.png",
  name: "MAGIC BEANS",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-013",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-004.png",
  name: "NIGHT MARKET",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-004",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-036.png",
  name: "CHILIHUAHUA",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-036",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-032.png",
  name: "NIGHT MARKET 2",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-032",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-018.png",
  name: "RICH MAN",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-018",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-035.png",
  name: "EGYPT BONANZA",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-035",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-017.png",
  name: "GOLD RUSH",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-017",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-010.png",
  name: "DA LE MEN",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-010",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-027.png",
  name: "MERGE MAGIC",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-027",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-019.png",
  name: "GLORY OF ROME",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-019",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-011.png",
  name: "ANIMAL RACING",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-011",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-001.png",
  name: "GOLDEN PANTHER",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-001",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-014.png",
  name: "COWBOYS",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-014",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-012.png",
  name: "LUXURY GOLDEN PANTHER",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-012",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-016.png",
  name: "TREASURE CRUISE",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-016",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-007.png",
  name: "CHINESE NEW YEAR",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-007",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-022.png",
  name: "CHINESE NEW YEAR 2",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-022",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-031.png",
  name: "FORTUNE EGG",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-031",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-008.png",
  name: "FORTUNE KOI",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-008",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-039.png",
  name: "FORTUNE MONEY BOOM",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-039",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-037.png",
  name: "FORTUNE SHEEP",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-037",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-009.png",
  name: "HAPPY DUO BAO",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-009",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-003.png",
  name: "HOT POT PARTY",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-003",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-038.png",
  name: "LUCKY FORTUNES 3x3",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-038",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-021.png",
  name: "Lucky Fortunes",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-021",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-006.png",
  name: "PANDA DRAGON BOAT",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-006",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-005.png",
  name: "PONG PONG HU",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-005",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-040.png",
  name: "QUEEN OF INCA",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-040",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-020.png",
  name: "Robin Hood",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-020",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-033.png",
  name: "SUPER ELEMENTS",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-033",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-002.png",
  name: "THREE LITTLE PIGS",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-002",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-015.png",
  name: "WAR OF THE UNIVERSE",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-015",
},
{
  img: "../assets/images/slot/fc/FC-SLOT-030.png",
  name: "WIN WIN NEKO",
  category:"slot",
  type:"FC",
  platForm: "FC",
  gameType: "SLOT",
  casinoType: "FC-SLOT-030",
},


 // -------------------SLOT FC END-----------------

  // -------------------SLOT SPADE START-----------------
  {
    img: "../assets/images/slot/spade/SG-SLOT-039.png",
    name: "5FortuneDragons",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-039",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-030.png",
    name: "5FortuneSA",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-030",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-069.png",
    name: "888",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-069",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-031.png",
    name: "BabyCaiShen",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-031",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-021.png",
    name: "BigProsperitySA",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-021",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-084.png",
    name: "BrothersKingdom",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-084",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-037.png",
    name: "CaiShen888",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-037",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-001.png",
    name: "Candy Pop",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-001",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-078.png",
    name: "CrazyBomber",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-078",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-055.png",
    name: "DaFuXiaoFu",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-055",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-070.png",
    name: "DancingFever",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-070",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-038.png",
    name: "DoubleFortunes",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-038",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-019.png",
    name: "DragonGoldSA",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-019",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-045.png",
    name: "Fafafa",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-045",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-003.png",
    name: "FaFaFa2",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-003",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-079.png",
    name: "FierySevens",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-079",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-018.png",
    name: "GoldenChicken",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-018",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-032.png",
    name: "GoldenLotusSE",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-032",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-009.png",
    name: "GoldenMonkey",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-009",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-058.png",
    name: "GoldenWhale",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-058",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-022.png",
    name: "GreatStarsSA",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-022",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-068.png",
    name: "HeroesGame",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-068",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-049.png",
    name: "HighwayFortune",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-049",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-017.png",
    name: "HoYeahMonkey",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-017",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-023.png",
    name: "IcelandSA",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-023",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-012.png",
    name: "JungleKing",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-012",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-106.png",
    name: "Fiery Sevens Exclusive",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-106",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-105.png",
    name: "Royale House",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-105",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-112.png",
    name: "Fruits Mania",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-112",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-074.png",
    name: "Mega7",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-074",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-098.png",
    name: "Royal Katt",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-098",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-100.png",
    name: "Gold Rush Cowboy",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-100",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-082.png",
    name: "GoldPanther",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-082",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-111.png",
    name: "Gold Panther Maxways",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-111",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-121.png",
    name: "CAISHEN DELUXE MAXWAYS",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-121",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-101.png",
    name: "Candy Candy",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-101",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-124.png",
    name: "Hammer of Thunder",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-124",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-096.png",
    name: "Rich Caishen",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-096",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-092.png",
    name: "Sugar Bonanza",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-092",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-115.png",
    name: "Sugar Party",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-115",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-104.png",
    name: "Tiger Dance",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-104",
  },
  {
    img: "../assets/images/slot/spade/SG-SLOT-099.png",
    name: "Wild Wet Win",
    category:"slot",
    type:"SPADE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-099",
  },

   // -------------------SLOT SPADE END-----------------

   // -------------------SLOT YESBINGO STARt-----------------
   {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-022.png",
    name: "Royal Ace",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-022",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-001.png",
    name: "FortuneTreasure",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-001",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-020.png",
    name: "Rolling Fortune",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-020",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-026.png",
    name: "Golden Aztec Mega",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-026",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-019.png",
    name: "Golden Aztec",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-019",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-011.png",
    name: "Rolling 7",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-011",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-003.png",
    name: "DragoNova",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-003",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-002.png",
    name: "MoneyHorse",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-002",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-018.png",
    name: "Boom Boom Marmot",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-018",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-021.png",
    name: "Mermaid Slingo",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-021",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-008.png",
    name: "MagicLamp",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-008",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-023.png",
    name: "MagicLampLink",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-023",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-010.png",
    name: "LuckyStar",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-010",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-009.png",
    name: "Gladiator",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-009",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-013.png",
    name: "Fancy Egypt",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-013",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-007.png",
    name: "FaFaDragon",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-007",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-014.png",
    name: "FortuneCat",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-014",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-017.png",
    name: "King Arthur Gold",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-017",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-006.png",
    name: "Power Mask",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-006",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-012.png",
    name: "Thor Fortune",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-012",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-027.png",
    name: "Sugar Crush",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-027",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-015.png",
    name: "CasinoFantasy",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-015",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-029.png",
    name: "Crazy Money",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-029",
  },

  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-028.png",
    name: "Diva Ace",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-028",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-024.png",
    name: "GladiatorLink",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-024",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-030.png",
    name: "Lucky Meow",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-030",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-004.png",
    name: "PowerFortune",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-004",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-005.png",
    name: "PowerLion",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-005",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-031.png",
    name: "Royal Hunter",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-031",
  },
  {
    img: "../assets/images/slot/yesbingo/YesBingo-SLOT-025.png",
    name: "Super Egypt",
    category:"slot",
    type:"YESBINGO",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-025",
  },



   // -------------------SLOT YESBINGO END-----------------

   // -------------------SLOT RT START-----------------
{
    img: "../assets/images/slot/rt/RT-SLOT-004.png",
    name: "Chinese Treasures",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-004",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-010.png",
    name: "Chinese Treasures",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-010",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-011.png",
    name: "Dragon's Luck Power Reels",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-011",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-014.png",
    name: "Epic Journey",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-014",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-018.png",
    name: "Flaming Fox",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-018",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-020.png",
    name: "Fortune Fest",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-020",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-021.png",
    name: "Fortune House",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-021",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-022.png",
    name: "Fruit Blox",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-022",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-038.png",
    name: "Lucky Easter",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-038",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-048.png",
    name: "Mega Rise",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-048",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-064.png",
    name: "Phoenix",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-064",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-067.png",
    name: "Royal Gems",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-067",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-003.png",
    name: "Blue Diamond",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-003",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-063.png",
    name: "Red Diamond",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-063",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-016.png",
    name: "Five Star",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-016",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-047.png",
    name: "Mega Pyramid",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-047",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-123.png",
    name: "Cash Ultimate",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-123",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-120.png",
    name: "Dragon King: Legend of the Seas",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-120",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-168.png",
    name: "War of Gods",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-168",
  },
  {
    img: "../assets/images/slot/rt/RT-SLOT-083.png",
    name: "Wild Circus",
    category:"slot",
    type:"RT",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-083",
  },



 // -------------------SLOT RT END-----------------


 // -------------------SLOT PT START-----------------
 {
  img: "../assets/images/slot/pt/PT-SLOT-007.png",
  name: "Sky Queen",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-007",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-008.png",
  name: "Blue Wizard",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-008",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-025.png",
  name: "Eternal Lady",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-025",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-092.png",
  name: "Mega Fire Blaze: Big Circus!",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-092",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-033.png",
  name: "Jinns Moon",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-033",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-090.png",
  name: "Leprechaun's Luck: Cash Collect",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-090",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-087.png",
  name: "Hit Bar™",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-087",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-107.png",
  name: "Silver Bullet Bandit: Cash Collect",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-107",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-347.png",
  name: "Gold Trio",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-347",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-101.png",
  name: "Sahara Riches: Cash Collect",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-101",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-188.png",
  name: "Legacy of the Wilds 2",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-188",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-017.png",
  name: "Buffalo Blitz II",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-017",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-076.png",
  name: "Gold Hit: O'Reilly's Riches",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-076",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-053.png",
  name: "Amazon Wild",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-053",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-199.png",
  name: "Blazing Bells",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-199",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-074.png",
  name: "Fire Blaze Quattro: Celtic Charm",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-074",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-337.png",
  name: "Witches: Cash Collect",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-337",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-267.png",
  name: "Diamond Match",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-267",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-095.png",
  name: "Heroes Arrow™",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-095",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-330.png",
  name: "Alohawaii: Cash Collect",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-330",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-103.png",
  name: "Anaconda Uncoiled",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-103",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-239.png",
  name: "Azteca Bonus Lines",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-239",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-125.png",
  name: "White King II",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-125",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-202.png",
  name: "Book of Kings",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-202",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-003.png",
  name: "Buffalo Blitz",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-003",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-108.png",
  name: "Joker Rush",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-108",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-198.png",
  name: "Adventure Trail",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-198",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-113.png",
  name: "Age of Egypt",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-113",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-130.png",
  name: "Berry Berry Bonanza",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-130",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-351.png",
  name: "Candy Roll™",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-351",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-360.png",
  name: "Diamond Match Deluxe",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-360",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-021.png",
  name: "Epic Ape",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-021",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-082.png",
  name: "Football Rules",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-082",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-183.png",
  name: "Gold Pile: Toltec Treasure",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-183",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-236.png",
  name: "Grand Junction: Golden Guns",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-236",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-244.png",
  name: "Mega Fire Blaze: Emperor of Rome",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-244",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-197.png",
  name: "Panda Luck",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-197",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-406.png",
  name: "Piggies and the Bank: LuckyTap A1",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-406",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-355.png",
  name: "Queen's Mystery",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-355",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-143.png",
  name: "Royal Respin Deluxe",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-143",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-016.png",
  name: "Safari Heat",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-016",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-346.png",
  name: "Samurai Fury™",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-346",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-055.png",
  name: "White King",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-055",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-180.png",
  name: "Zhao Cai Jin Bao 2",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-180",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-014.png",
  name: "Zhao Cai Tong Zi",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-014",
},
{
  img: "../assets/images/slot/pt/PT-SLOT-164.png",
  name: "Zodiac Charms",
  category:"slot",
  type:"PT",
  platForm: "PT",
  gameType: "SLOT",
  casinoType: "PT-SLOT-164",
},


 // -------------------SLOT PT END-----------------

 // -------------------SLOT JOKER START-----------------
 {
  img: "../assets/images/slot/joker/JOKER-SLOT-003.png",
  name: "Alice",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-003",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-005.png",
  name: "Ancient Artifact",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-005",
},

{
  img: "../assets/images/slot/joker/JOKER-SLOT-019.png",
  name: "Bonus Bear",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-019",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-024.png",
  name: "Burning Pearl",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-024",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-025.png",
  name: "Bushido Blade",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-025",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-026.png",
  name: "Caishen Riches",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-026",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-027.png",
  name: "Captain's Treasure",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-027",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-028.png",
  name: "Captains Treasure Pro",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-028",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-029.png",
  name: "Captains Treasure Progressive",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-029",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-031.png",
  name: "China",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-031",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-032.png",
  name: "Chinese Boss",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-032",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-034.png",
  name: "Columbus",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-034",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-040.png",
  name: "Dolphin Reef",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-040",
},
{
  img: "../assets/images/slot/joker/JOKER-SLOT-041.png",
  name: "Dolphin Treasure",
  category:"slot",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-041",
},
 // -------------------SLOT JOKER END-----------------

// -------------------SLOT NETENT START---------------


{
  img: "../assets/images/slot/netent/NETENT-SLOT-004.png",
  name: "Dark King: Forbidden Riches™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-004",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-056.png",
  name: "Codex of Fortune™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-056",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-013.png",
  name: "Fruit Shop™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-013",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-115.png",
  name: "Wolf Cub™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-115",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-014.png",
  name: "Divine Fortune™ Megaways™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-014",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-126.png",
  name: "Funk Master™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-126",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-053.png",
  name: "Gorilla Kingdom™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-053",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-094.png",
  name: "Grand Spinn™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-094",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-073.png",
  name: "Asgardian Stones™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-073",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-128.png",
  name: "Bee Hive Bonanza",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-128",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-132.png",
  name: "Wonders of Christmas",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-132",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-050.png",
  name: "Irish Pot Luck™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-050",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-069.png",
  name: "Scruffy Duck™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-069",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-109.png",
  name: "Silverback Gold™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-109",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-076.png",
  name: "Dazzle Me™ Megaways™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-076",
},
{
  img: "../assets/images/slot/netent/NETENT-SLOT-031.png",
  name: "Jingle Spin™",
  category:"slot",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "SLOT",
  casinoType: "NETENT-SLOT-031",
},

// -------------------SLOT NETENT END-----------------

// -------------------SLOT PLAY8 START----------------
{
  img: "../assets/images/slot/play8/P8-SLOT-003.png",
  name: "Great Blue",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-003",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-006.png",
  name: "Dolphin Reef",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-006",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-010.png",
  name: "Cherry Love",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-010",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-024.png",
  name: "WhiteKing",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-024",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-002.png",
  name: "Safari Heat",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-002",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-027.png",
  name: "SuperLuckyCat",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-027",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-001.png",
  name: "Panther Moon",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-001",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-009.png",
  name: "Silent Samurai",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-009",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-014.png",
  name: "Sea Captain",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-014",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-013.png",
  name: "Big Shot",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-013",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-023.png",
  name: "BigProsperity",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-023",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-029.png",
  name: "CrazyMonkey",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-029",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-022.png",
  name: "DragonGold",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-022",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-017.png",
  name: "Emperor Gate",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-017",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-005.png",
  name: "Pan Jin Lian",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-005",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-032.png",
  name: "Pyramid",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-032",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-033.png",
  name: "Qin",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-033",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-026.png",
  name: "RichMouse",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-026",
},
{
  img: "../assets/images/slot/play8/P8-SLOT-034.png",
  name: "SangoWarlord",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-034",
},

{
  img: "../assets/images/slot/play8/P8-SLOT-028.png",
  name: "WongChoy",
  category:"slot",
  type:"PLAY8",
  platForm: "PLAY8",
  gameType: "SLOT",
  casinoType: "P8-SLOT-028",
},
// -------------------SLOT PLAY8 END-----------------

// -------------------SLOT KINGMAKER START-----------------
{
  img: "../assets/images/slot/km/KM-SLOT-001.webp",
  name: "Sugar Blast",
  category:"slot",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "SLOT",
  casinoType: "KM-SLOT-001",
},
{
  img: "../assets/images/slot/km/KM-SLOT-002.webp",
  name: "Gems of Valhalla",
  category:"slot",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "SLOT",
  casinoType: "KM-SLOT-002",
},
{
  img: "../assets/images/slot/km/KM-SLOT-005.webp",
  name: "Rooster Blitz",
  category:"slot",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "SLOT",
  casinoType: "KM-SLOT-005",
},
// -------------------SLOT KINGMAKER END-----------------

// -------------------SLOT DRAGOONSOFT START-----------------

{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-003.webp",
  name: "777",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-003",
 
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-004.webp",
  name: "Get Money",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-004",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-012.webp",
  name: "Over Dragon's Gate",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-012",
 
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-002.webp",
  name: "Phoenix",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-002",

},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-011.webp",
  name: "Diamond Mogul",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-011",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-005.webp",
  name: "Rich Now",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-005",
  category: "DRAGOONSOFT",
  type: "SLOT",
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-008.webp",
  name: "Dracula",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-008",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-020.webp",
  name: "Bust Treasury",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-020",
  
},

{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-001.webp",
  name: "Coin Cat",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-001",
 
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-015.webp",
  name: "Egypt Oracle",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-015",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-018.webp",
  name: "Rich Lion",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-018",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-017.webp",
  name: "Candy Dynasty",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-017",
 
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-007.webp",
  name: "Doggy Wealth",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-007",
 
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-016.webp",
  name: "Caishen Coming",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-016",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-006.webp",
  name: "Chef Lady",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-006",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-009.webp",
  name: "Maya King",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-009",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-010.webp",
  name: "Pirate King",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-010",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-013.webp",
  name: "Great Lion",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-013",
 
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-014.webp",
  name: "Ultra Treasure",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-014",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-019.webp",
  name: "Monkey King",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-019",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-075.webp",
  name: "Mahjong Win",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-075",
  
},
{
  img: "../assets/images/slot/dragonsoft/DRAGOONSOFT-SLOT-076.webp",
  name: "Super Hoo Hey How",
  category: "slot",
  type: "DRAGOONSOFT",
  platForm: "DRAGOONSOFT",
  gameType: "SLOT",
  casinoType: "DRAGOONSOFT-SLOT-076",
  
},

// -------------------SLOT DRAGOONSOFT END-----------------

// SLOT BTG START -----
{
  img: "../assets/images/slot/btg/BTG-SLOT-001.webp",
  name: "Apollo Pays",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-001",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-002.webp",
  name: "Castle of Terror",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-002",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-003.webp",
  name: "Rasputin Megaways",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-003",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-004.webp",
  name: "Bonanza",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-004",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-005.webp",
  name: "Extra Chilli",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-005",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-006.webp",
  name: "White Rabbit",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-006",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-007.webp",
  name: "Millionaire",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-007",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-008.webp",
  name: "Golden Catch",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-008",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-009.webp",
  name: "Danger High Voltage",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-009",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-010.webp",
  name: "Christmas Bonanza",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-010",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-011.webp",
  name: "Gifts of Fortune",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-011",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-012.webp",
  name: "The Race",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-012",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-013.webp",
  name: "Over The Moon",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-013",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-014.webp",
  name: "Vegas Rush",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-014",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-015.webp",
  name: "Beef Lightning",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-015",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-016.webp",
  name: "Gold Megaways",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-016",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-017.webp",
  name: "Big Bad Bison",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-017",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-018.webp",
  name: "Millionaire Rush",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-018",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-019.webp",
  name: "Max Megaways",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-019",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-020.webp",
  name: "Royal Mint",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-020",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-021.webp",
  name: "Christmas Catch",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-021",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-022.webp",
  name: "Bonanza Falls",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-022",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-023.webp",
  name: "Outlaw",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-023",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-024.webp",
  name: "Vegas Megaways",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-024",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-025.webp",
  name: "Star Clusters",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-025",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-026.webp",
  name: "Wild Portals",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-026",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-027.webp",
  name: "Max Megaways 2",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-027",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-028.webp",
  name: "Slot Vegas - Fully Loaded",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-028",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-029.webp",
  name: "Pirate Pays",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-029",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-030.webp",
  name: "Wild Flower",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-030",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-031.webp",
  name: "Pop",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-031",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-032.webp",
  name: "Kingmaker Fully Loaded",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-032",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-033.webp",
  name: "Wild Unicorns",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-033",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-034.webp",
  name: "Diamond Fruits",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-034",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-035.webp",
  name: "Donuts",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-035",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-036.webp",
  name: "Lil Devil",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-036",
  
},

{
  img: "../assets/images/slot/btg/BTG-SLOT-038.webp",
  name: "Opal Fruits",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-038",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-039.webp",
  name: "Sanctuary",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-039",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-040.webp",
  name: "Panda Money",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-040",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-041.webp",
  name: "Fireworks Megaways",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-041",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-042.webp",
  name: "The Final Countdown",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-042",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-043.webp",
  name: "Spicy Meatballs",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-043",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-044.webp",
  name: "Chocolates",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-044",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-045.webp",
  name: "King of Cats",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-045",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-046.webp",
  name: "More Turkey",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-046",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-047.webp",
  name: "Trigger Happy",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-047",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-048.webp",
  name: "Lucky Streak Mk2",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-048",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-049.webp",
  name: "Cyberslot Megaclusters",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-049",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-050.webp",
  name: "Fizzy Pennyslot",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-050",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-051.webp",
  name: "Holy Diver Megaways",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-051",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-052.webp",
  name: "Millionaire Mystery Box",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-052",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-053.webp",
  name: "Book of Gods",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-053",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-054.webp",
  name: "Boo",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-054",
  
},
{
  img: "../assets/images/slot/btg/BTG-SLOT-055.webp",
  name: "Queen of Riches",
  category: "slot",
  type: "BTG",
  platForm: "BTG",
  gameType: "SLOT",
  casinoType: "BTG-SLOT-055",
  
},

// SLOT BTG END -----

 // SLOT NLC START -----
 {
  img: "../assets/images/slot/nlc/NLC-SLOT-001.webp",
  name: "Das x Boot",
  category: "slot",
  type: "NLC",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-001",
   
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-002.webp",
  name: "Mental",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-002",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-003.webp",
  name: "Punk Toilet",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-003",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-004.webp",
  name: "Serial",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-004",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-005.webp",
  name: "Tomb of Akhenaten",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-005",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-006.webp",
  name: "Tombstone RIP",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-006",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-007.webp",
  name: "Folsom Prison",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-007",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-008.webp",
  name: "Fire In The Hole xBomb",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-008",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-009.webp",
  name: "Dead Canary",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-009",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-010.webp",
  name: "Deadwood xNudge",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-010",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-011.webp",
  name: "Evil Goblins xBomb",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-011",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-012.webp",
  name: "Punk Rocker",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-012",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-013.webp",
  name: "xWays Hoarder xSplit",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-013",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-014.webp",
  name: "Infectious 5 xWays",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-014",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-015.webp",
  name: "Misery Mining",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-015",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-016.webp",
  name: "Book Of Shadows",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-016",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-017.webp",
  name: "Remember Gulag",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-017",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-018.webp",
  name: "Pearl Harbor",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-018",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-019.webp",
  name: "Road Rage",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-019",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-020.webp",
  name: "Bonus Bunnies",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-020",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-021.webp",
  name: "Little Bighorn",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-021",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-022.webp",
  name: "SeriaThe Borderl",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-022",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-023.webp",
  name: "The Rave",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-023",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-024.webp",
  name: "Karen Maneater",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-024",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-025.webp",
  name: "True Grit Redemption",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-025",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-026.webp",
  name: "Legion X",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-026",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-027.webp",
  name: "El Pasa Gunfight xNudge",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-027",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-028.webp",
  name: "Bushido Way xNudge",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-028",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-029.webp",
  name: "East Coast Vs West Coast",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-029",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-030.webp",
  name: "San Quentin xWays",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-030",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-031.webp",
  name: "Warrior Graveyard xNudge",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-031",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-032.webp",
  name: "Monkey's Gold xPays",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-032",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-033.webp",
  name: "Buffalo Hunter",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-033",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-034.webp",
  name: "Immortal Fruits",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-034",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-035.webp",
  name: "Golden Genie And The Walking Wilds",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-035",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-036.webp",
  name: "Milky Ways",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-036",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-037.webp",
  name: "Harlequin Carnival",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-037",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-038.webp",
  name: "Gaelic Gold",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-038",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-039.webp",
  name: "Barbarian Fury",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-039",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-040.webp",
  name: "Poison Eve",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-040",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-041.webp",
  name: "Dragon Tribe",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-041",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-042.webp",
  name: "Tomb of Nefertiti",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-042",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-043.webp",
  name: "Manhattan Goes Wild",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-043",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-044.webp",
  name: "Pixies vs Pirates",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-044",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-045.webp",
  name: "Hot 4 Cash",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-045",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-046.webp",
  name: "Thor: Hammer Time",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-046",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-047.webp",
  name: "Tractor Beam",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-047",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-048.webp",
  name: "Mayan Magic Wildfire",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-048",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-049.webp",
  name: "Tombstone",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-049",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-050.webp",
  name: "Starstruck",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-050",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-051.webp",
  name: "Owls",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-051",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-052.webp",
  name: "Fruits",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-052",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-053.webp",
  name: "Ice Ice Yeti",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-053",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-054.webp",
  name: "Dungeon Quest",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-054",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-055.webp",
  name: "Hot Nudge",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-055",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-056.webp",
  name: "Coins of Fortune",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-056",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-057.webp",
  name: "Tesla Jolt",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-057",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-058.webp",
  name: "Casino Win Spin",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-058",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-059.webp",
  name: "Kitchen Drama: Bbq Frenzy",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-059",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-060.webp",
  name: "WiXX",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-060",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-061.webp",
  name: "The Creepy Carnival",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-061",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-062.webp",
  name: "Rock Bottom",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-062",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-063.webp",
  name: "Walk of Shame",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-063",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-064.webp",
  name: "Benji Killed in Vegas",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-064",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-065.webp",
  name: "Blood & Shadow",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-065",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-066.webp",
  name: "Kiss My Chainsaw",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-066",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-067.webp",
  name: "Disturbed",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-067",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-068.webp",
  name: "Whacked",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-068",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-069.webp",
  name: "gluttony",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-069",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-070.webp",
  name: "Bounty Hunters xNudge",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-070",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-071.webp",
  name: "DJ Psycho",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-071",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-072.webp",
  name: "The Cage",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-072",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-073.webp",
  name: "The Crypt",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-073",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-074.webp",
  name: "True kult",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-074",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-075.webp",
  name: "Brick Snake 2000",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-075",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-076.webp",
  name: "D Day",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-076",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-077.webp",
  name: "Devil's Crossroad",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-077",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-078.webp",
  name: "Fire In The Hole 2",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-078",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-079.webp",
  name: "Jingle Balls",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-079",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-080.webp",
  name: "Land Of The Free",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-080",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-081.webp",
  name: "Possessed",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-081",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-082.webp",
  name: "Tombstone No Mercy",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-082",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-083.webp",
  name: "Nine To Five",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-083",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-084.webp",
  name: "Roadkill",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-084",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-085.webp",
  name: "Space Donkey",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-085",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-086.webp",
  name: "Ugliest Catch",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-086",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-087.webp",
  name: "Kenneth Must Die",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-087",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-088.webp",
  name: "LONER",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-088",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-089.webp",
  name: "Punk Rocker 2",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-089",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-090.webp",
  name: "Beheaded",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-090",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-091.webp",
  name: "Deadwood R.I.P",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-091",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-092.webp",
  name: "Apocalypse Super xNudge®",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-092",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-093.webp",
  name: "Outsourced",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-093",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-094.webp",
  name: "Stockholm Syndrome",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-094",
    category: "slot",
  type: "NLC",
},
{
  img: "../assets/images/slot/nlc/NLC-SLOT-095.webp",
  name: "Skate or Die",
  platForm: "NLC",
  gameType: "SLOT",
  casinoType: "NLC-SLOT-095",
    category: "slot",
  type: "NLC",
},
      // SLOT NLC END -----

// -------------------SLOT PP START-----------------


{
  img: "../assets/images/slot/pp/PP-SLOT-169.png",
  name: "888 Gold",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-169",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-002.png",
  name: "Gates of Olympus",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-002",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-411.png",
  name: "Gates of Olympus 1000",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-411",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-082.png",
  name: "Sweet Bonanza",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-082",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-189.png",
  name: "Starlight Princess",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-189",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-111.png",
  name: "Aztec Gems",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-111",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-019.png",
  name: "Cowboys Gold",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-019",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-107.png",
  name: "Joker's Jewels",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-107",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-035.png",
  name: "Aztec Gems Deluxe",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-035",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-050.png",
  name: "Hot to Burn",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-050",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-235.png",
  name: "Fire Strike 2",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-235",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-394.png",
  name: "Twilight Princess",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-394",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-212.png",
  name: "Book of Fallen",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-212",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-346.png",
  name: "Zeus vs Hades - Gods of War",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-346",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-443.png",
  name: "Sugar Rush 1000",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-443",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-282.png",
  name: "Black Bull",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-282",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-418.png",
  name: "5 Frozen Charms Megaways",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-418",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-067.png",
  name: "Sweet Bonanza Xmas",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-067",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-062.png",
  name: "Buffalo King",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-062",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-334.png",
  name: "3 Dancing Monkeys",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-334",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-125.png",
  name: "8 Dragons",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-125",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-379.png",
  name: "8 Golden Dragon Challenge",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-379",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-259.png",
  name: "Bomb Bonanza",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-259",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-213.png",
  name: "Bubble Pop™",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-213",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-421.png",
  name: "Candy Jar Clusters",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-421",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-508.png",
  name: "Candy Corner",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-508",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-453.png",
  name: "Candy Blitz Bombs",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-453",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-381.png",
  name: "Candy Blitz",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-381",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-121.png",
  name: "Caishen's Gold",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-121",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-281.png",
  name: "Candy Stars",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-281",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-204.png",
  name: "Candy Village",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-204",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-201.png",
  name: "Cash Bonanza",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-201",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-009.png",
  name: "Congo Cash",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-009",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-284.png",
  name: "Crown of Fire",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-284",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-051.png",
  name: "Dance Party",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-051",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-228.png",
  name: "Elemental Gems Megaways",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-228",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-087.png",
  name: "Extra Juicy",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-087",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-322.png",
  name: "Fire Archer",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-322",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-319.png",
  name: "Fish Eye",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-319",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-217.png",
  name: "Fortune of Giza",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-217",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-046.png",
  name: "Fruit Party",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-046",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-436.png",
  name: "Gates of Olympus Dice",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-436",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-025.png",
  name: "Gems Bonanza",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-025",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-114.png",
  name: "Gold Rush",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-114",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-089.png",
  name: "Golden Pig",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-089",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-066.png",
  name: "Greek Gods",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-066",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-076.png",
  name: "Hot Chilli",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-076",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-076.png",
  name: "Hot Chilli",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-076",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-311.png",
  name: "Hot Pepper",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-311",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-341.png",
  name: "Jasmine Dreams",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-341",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-353.png",
  name: "Jewel Rush",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-353",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-072.png",
  name: "John Hunter and the Tomb of the Scarab Queen",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-072",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-004.png",
  name: "Joker King",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-004",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-358.png",
  name: "Joker Race",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-358",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-254.png",
  name: "Koi Pond",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-254",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-305.png",
  name: "Lucky Fishing Megaways",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-305",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-063.png",
  name: "Magic Journey",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-063",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-044.png",
  name: "Money Money Money",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-044",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-166.png",
  name: "Money Roll",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-166",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-078.png",
  name: "Monkey Warrior",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-078",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-178.png",
  name: "Panda Fortune 2",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-178",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-246.png",
  name: "Snakes and Ladders Megadice",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-246",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-038.png",
  name: "Street Racer",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-038",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-397.png",
  name: "Sugar Supreme Powernudge",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-397",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-071.png",
  name: "Super JOKER",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-071",
},{
  img: "../assets/images/slot/pp/PP-SLOT-022.png",
  name: "The Dragon Tiger",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-022",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-392.png",
  name: "The Money Men Megaways",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-392",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-248.png",
  name: "Tic Tac Take",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-248",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-425.png",
  name: "Trees of Treasure",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-425",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-096.png",
  name: "Vegas Magic",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-096",
},

{
  img: "../assets/images/slot/pp/PP-SLOT-119.png",
  name: "Vegas Nights",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-119",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-088.png",
  name: "Wild Gladiators",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-088",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-185.png",
  name: "Yum Yum Powerways",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-185",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-252.png",
  name: "Zombie Carnival",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-252",
},
{
  img: "../assets/images/slot/pp/PP-SLOT-437.png",
  name: "Zombie Train",
  category:"slot",
  type:"PP",
  platForm: "PP",
  gameType: "SLOT",
  casinoType: "PP-SLOT-437",
},

// -------------------SLOT PP END-----------------












// -------------------TABLE JILI START-----------------

  {
    img: "../assets/images/table/jili/JILI-TABLE-011.png",
    name: "7up7down",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-011",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-002.png",
    name: "AK47",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-002",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-003.png",
    name: "Andar Bahar",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-003",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-004.png",
    name: "Rummy",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-004",
  },

  {
    img: "../assets/images/table/jili/JILI-TABLE-017.png",
    name: "Sic Bo",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-017",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-027.png",
    name: "Golden Land",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-027",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-028.png",
    name: "PAPPU",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-028",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-001.png",
    name: "Teen Patti",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-001",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-036.png",
    name: "wheel",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-036",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-023.png",
    name: "Color Game",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-023",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-031.png",
    name: "Tower",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-031",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-039.png",
    name: "Color Prediction",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-039",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-048.png",
    name: "Pool Rummy",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-048",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-037.png",
    name: "Mines Gold",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-037",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-041.png",
    name: "Plinko",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-041",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-012.png",
    name: "Dragon & Tiger",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-012",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-005.png",
    name: "NumberKing",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-005",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-006.png",
    name: "Poker King",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-006",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-010.png",
    name: "TeenPatti Joker",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-010",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-014.png",
    name: "Baccarat",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-014",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-007.png",
    name: "Big small",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-007",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-044.png",
    name: "Blackjack",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-044",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-046.png",
    name: "Blackjack Lucky Ladies",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-046",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-062.png",
    name: "Boxing Extravaganza",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-062",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-009.png",
    name: "Callbreak",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-009",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-013.png",
    name: "Callbreak Quick",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-013",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-049.png",
    name: "Caribbean Stud Poker",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-049",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-060.png",
    name: "Cricket Roulette",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-060",
  },

  {
    img: "../assets/images/table/jili/JILI-TABLE-053.png",
    name: "European Roulette",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-053",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-057.png",
    name: "Fish Prawn Crab",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-057",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-066.png",
    name: "Fortune Roulette",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-066",
  },

  {
    img: "../assets/images/table/jili/JILI-TABLE-067.png",
    name: "Go For Champion",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-067",
  },

  {
    img: "../assets/images/table/jili/JILI-TABLE-032.png",
    name: "HILO",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-032",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-065.png",
    name: "Keno Bonus Number",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-065",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-021.png",
    name: "Ludo Quick",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-021",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-042.png",
    name: "MINI FLUSH",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-042",
  },
  {
    img: "../assets/images/table/jili/JILI-SLOT-032.png",
    name: "Matka India",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-SLOT-032",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-033.png",
    name: "Mines",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-033",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-038.png",
    name: "Pusoy Go",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-038",
  },

  {
    img: "../assets/images/table/jili/JILI-TABLE-054.png",
    name: "Speed Baccarat",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-054",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-059.png",
    name: "Super Ace Scratch",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-059",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-064.png",
    name: "Super E-Sabong",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-064",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-063.png",
    name: "Thai Hilo",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-063",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-025.png",
    name: "Tongits Go",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-025",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-061.png",
    name: "Ultimate Texas Hold'em",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-061",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-050.png",
    name: "Video Poker",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-050",
  },
  {
    img: "../assets/images/table/jili/JILI-TABLE-016.png",
    name: "TeenPatti 20-20",
    category:"table",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-016",
  },



// -------------------TABLE JILI END-----------------


// -------------------TABLE KINGMAKER START-----------------
{
  img: "../assets/images/table/kingmaker/KM-TABLE-028.png",
  name: "7 Up 7 Down",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-028",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-024.png",
  name: "FanTanClassic",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-024",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-036.png",
  name: "Coin Toss",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-036",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-015.png",
  name: "sicbo",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-015",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-022.png",
  name: "CardMatka",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-022",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-046.png",
  name: "Teen Patti",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-046",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-010.png",
  name: "belangkai2",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-010",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-021.png",
  name: "NumberMatka",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-021",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-032.png",
  name: "Andar Bahar",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-032",
},

{
  img: "../assets/images/table/kingmaker/KM-TABLE-023.png",
  name: "xocdia2",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-023",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-039.png",
  name: "32 Cards",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-039",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-050.png",
  name: "Colour Game",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-050",
},

{
  img: "../assets/images/table/kingmaker/KM-TABLE-014.png",
  name: "vn_fish-prawn-crab",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-014",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-043.png",
  name: "Bonus Dice",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-043",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-030.png",
  name: "jhandimunda",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-030",
},

{
  img: "../assets/images/table/kingmaker/KM-TABLE-026.png",
  name: "Baccarat",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-026",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-042.png",
  name: "Minesweeper",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-042",
},

{
  img: "../assets/images/table/kingmaker/KM-TABLE-047.png",
  name: "Heist",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-047",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-048.png",
  name: "HorseRacing",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-048",
},

{
  img: "../assets/images/table/kingmaker/KM-TABLE-049.png",
  name: "5 Card Poker",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-049",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-029.png",
  name: "bai buu",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-029",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-038.png",
  name: "Blackjack",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-038",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-052.png",
  name: "BolaTangkas",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-052",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-037.png",
  name: "Cards Hi Lo",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-037",
},

{
  img: "../assets/images/table/kingmaker/KM-TABLE-078.png",
  name: "Dice Duet",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-078",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-044.png",
  name: "Dota Hi-Lo",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-044",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-027.png",
  name: "european roulette",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-027",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-025.png",
  name: "Fruit Roulette",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-025",
},

{
  img: "../assets/images/table/kingmaker/KM-TABLE-060.png",
  name: "Ludo",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-060",
},

{
  img: "../assets/images/table/kingmaker/KM-TABLE-053.png",
  name: "Marble Knockout",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-053",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-065.png",
  name: "Pusoy",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-065",
},

{
  img: "../assets/images/table/kingmaker/KM-TABLE-017.png",
  name: "taixiu",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-017",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-063.png",
  name: "Tongits",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-063",
},
{
  img: "../assets/images/table/kingmaker/KM-TABLE-073.png",
  name: "video-poker",
  category:"table",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-073",
},
// -------------------TABLE KINGMAKER END-----------------



// -------------------TABLE SPRIBE START-----------------
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-003.png",
  name: "Dice",
  category:"table",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-003",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-008.png",
  name: "Mini Roulette",
  category:"table",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-008",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-005.png",
  name: "Mines",
  category:"table",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-005",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-006.png",
  name: "Hilo",
  category:"table",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-006",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-004.png",
  name: "Plinko",
  category:"table",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-004",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-002.png",
  name: "Goal",
  category:"table",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-002",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-009.png",
  name: "Hotline",
  category:"table",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-009",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-007.png",
  name: "Keno",
  category:"table",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-007",
},


// -------------------TABLE SPRIBE END-----------------

// -------------------TABLE PG START-----------------

{
  img: "../assets/images/table/pg/PG-TABLE-001.png",
  name: "Baccarat Deluxe",
  category:"table",
  type:"PG",
  platForm: "PG",
  gameType: "TABLE",
  casinoType: "PG-TABLE-001",
},

// -------------------TABLE PG END-----------------

// -------------------TABLE NETENT START-----------------

{
  img: "../assets/images/table/netent/NETENT-TABLE-001.png",
  name: "Blackjack Classic",
  category:"table",
  type:"NETENT",
  platForm: "NETENT",
  gameType: "TABLE",
  casinoType: "NETENT-TABLE-001",
},

// -------------------TABLE NETENT END-----------------

// -------------------LOTTERY JILI START-----------------

  {
    img: "../assets/images/lottery/jili/JILI-TABLE-034.png",
    name: "Bingo Adventure",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-034",
  },
  {
    img: "../assets/images/lottery/jili/JILI-TABLE-019.png",
    name: "Bingo Carnaval",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-019",
  },
  {
    img: "../assets/images/lottery/jili/JILI-TABLE-026.png",
    name: "Calaca Bingo",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-026",
  },
  {
    img: "../assets/images/lottery/jili/JILI-TABLE-043.png",
    name: "Candyland Bingo",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-043",
  },
  {
    img: "../assets/images/lottery/jili/JILI-TABLE-015.png",
    name: "Fortune Bingo",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-015",
  },
  {
    img: "../assets/images/lottery/jili/JILI-TABLE-040.png",
    name: "Keno",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-040",
  },
  {
    img: "../assets/images/lottery/jili/JILI-TABLE-020.png",
    name: "Lucky Bingo",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-020",
  },
  {
    img: "../assets/images/lottery/jili/JILI-TABLE-045.png",
    name: "Magic Lamp Bingo",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-045",
  },
  {
    img: "../assets/images/lottery/jili/JILI-TABLE-051.png",
    name: "Pearls of Bingo",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-051",
  },
  {
    img: "../assets/images/lottery/jili/JILI-TABLE-018.png",
    name: "Super Bingo",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-018",
  },
  {
    img: "../assets/images/lottery/jili/JILI-TABLE-029.png",
    name: "West Hunter Bingo",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-029",
  },

  {
    img: "../assets/images/lottery/jili/JILI-TABLE-008.png",
    name: "iRich Bingo",
    category:"lottery",
    type:"JILI",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-008",
  },


      // -------------------LOTTERY JILI END-----------------

      // -------------------LOTTERY KINGMAKER START-----------------

      {
        img: "../assets/images/lottery/kingmaker/KM-TABLE-051.png",
        name: "KM Power Ball",
        category:"lottery",
        type:"KINGMAKER",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-051",
      },
      {
        img: "../assets/images/lottery/kingmaker/KM-TABLE-018.png",
        name: "keno",
        category:"lottery",
        type:"KINGMAKER",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-018",
      },
      // -------------------LOTTERY KINGMAKER END-----------------

 // -------------------LOTTERY yellowbat START-----------------
 {
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-005.png",
  name: "Atlantis",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-005",
},
 {
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-006.png",
  name: "Beasty Bingo",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-006",
},
{
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-004.png",
  name: "BingoBingo",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-004",
},
{
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-003.png",
  name: "BingoBonanza",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-003",
},
{
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-011.png",
  name: "EZ Bingo",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-011",
},
{
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-008.png",
  name: "Heat Bingo",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-008",
},
{
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-010.png",
  name: "Joy Bingo",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-010",
},
{
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-009.png",
  name: "Lightning Bingo",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-009",
},
{
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-007.png",
  name: "MoneyBingo",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-007",
},
{
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-002.png",
  name: "OpenSesame",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-002",
},
{
  img: "../assets/images/lottery/yellowbat/YesBingo-BINGO-001.png",
  name: "WinCaiShen",
  category:"lottery",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "BINGO",
  casinoType: "YesBingo-BINGO-001",
},



// -------------------LOTTERY yellowbat END-----------------

// -------------------LOTTERY SABA START-----------------
{
  img: "../assets/images/lottery/saba/164.png",
  name: "SABA",
  category:"lottery",
  type:"SABA",
  platForm: "SABA",
  gameType: "VIRTUAL",
  casinoType: "SABA-VIRTUAL-001",
},

// -------------------LOTTERY SABA END-----------------
// ------------------- LOTTERY JOKER START-----------------
{
  img: "../assets/images/lottery/joker/JOKER-SLOT-024.png",
  name: "Burning Pearl",
  category:"lottery",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-024",
},
{
  img: "../assets/images/lottery/joker/JOKER-SLOT-026.png",
  name: "Caishen Riches",
  category:"lottery",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-026",
},
{
  img: "../assets/images/lottery/joker/JOKER-SLOT-030.png",
  name: "Chilli Hunter",
  category:"lottery",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-030",
},

{
  img: "../assets/images/lottery/joker/JOKER-SLOT-036.png",
  name: "Cryptomania",
  category:"lottery",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-036",
},
{
  img: "../assets/images/lottery/joker/JOKER-SLOT-040.png",
  name: "Koi Bingo",
  category:"lottery",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-040",
},
{
  img: "../assets/images/lottery/joker/JOKER-SLOT-117.png",
  name: "Neptune Bingo",
  category:"lottery",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-117",
},
{
  img: "../assets/images/lottery/joker/JOKER-SLOT-118.png",
  name: "Sheng Xiao Bingo",
  category:"lottery",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-118",
},
{
  img: "../assets/images/lottery/joker/JOKER-SLOT-185.png",
  name: "Zodiac Bingo",
  category:"lottery",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-185",
},
// -------------------LOTTERY JOKER END-----------------

// -------------------ARCADE JDB START-----------------
{
  img: "../assets/images/arcade/jdb/JDB-EGAME-010.png",
  name: "Beer Tycoon",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-010",
},
{
  img: "../assets/images/arcade/jdb/JDB-EGAME-003.png",
  name: "Birds and Animals",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-003",
},
{
  img: "../assets/images/arcade/jdb/JDB-EGAME-005.png",
  name: "CAISHEN PARTY",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-005",
},

{
  img: "../assets/images/arcade/jdb/JDB-EGAME-015.png",
  name: "GOAL",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-015",
},
{
  img: "../assets/images/arcade/jdb/JDB-EGAME-009.png",
  name: "Classic Mario",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-009",
},
{
  img: "../assets/images/arcade/jdb/JDB-EGAME-001.png",
  name: "Crazy King Kong",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-001",
},
{
  img: "../assets/images/arcade/jdb/JDB-EGAME-018.png",
  name: "Dice",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-018",
},
{
  img: "../assets/images/arcade/jdb/JDB-EGAME-004.png",
  name: "Happy New Year",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-004",
},
{
  img: "../assets/images/arcade/jdb/JDB-EGAME-016.png",
  name: "Hilo",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-016",
},
{
  img: "../assets/images/arcade/jdb/JDB-EGAME-008.png",
  name: "Huaguoshan Legends",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-008",
},
{
  img: "../assets/images/arcade/jdb/JDB-EGAME-011.png",
  name: "Jogo Do Bicho",
  category:"arcade",
  type:"JDB",
  platForm: "JDB",
  gameType: "EGAME",
  casinoType: "JDB-EGAME-011",
},





// -------------------ARCADE JDB END-----------------

// -------------------ARCADE FC START-----------------
{
  img: "../assets/images/arcade/fc/FC-EGAME-003.png",
  name: "CIRCUS DOZER",
  category:"arcade",
  type:"FC",
  platForm: "FC",
  gameType: "EGAME",
  casinoType: "FC-EGAME-003",
},
{
  img: "../assets/images/arcade/fc/FC-EGAME-004.png",
  name: "LIGHTNING BOMB",
  category:"arcade",
  type:"FC",
  platForm: "FC",
  gameType: "EGAME",
  casinoType: "FC-EGAME-004",
},
{
  img: "../assets/images/arcade/fc/FC-EGAME-007.png",
  name: "MINES",
  category:"arcade",
  type:"FC",
  platForm: "FC",
  gameType: "EGAME",
  casinoType: "FC-EGAME-007",
},
{
  img: "../assets/images/arcade/fc/FC-EGAME-001.png",
  name: "MONEY TREE DOZER",
  category:"arcade",
  type:"FC",
  platForm: "FC",
  gameType: "EGAME",
  casinoType: "FC-EGAME-001",
},
{
  img: "../assets/images/arcade/fc/FC-EGAME-005.png",
  name: "Super Color Game",
  category:"arcade",
  type:"FC",
  platForm: "FC",
  gameType: "EGAME",
  casinoType: "FC-EGAME-005",
},
{
  img: "../assets/images/arcade/fc/FC-EGAME-008.png",
  name: "TOWER",
  category:"arcade",
  type:"FC",
  platForm: "FC",
  gameType: "EGAME",
  casinoType: "FC-EGAME-008",
},
{
  img: "../assets/images/arcade/fc/FC-EGAME-002.png",
  name: "FA CHAI DOZER",
  category:"arcade",
  type:"FC",
  platForm: "FC",
  gameType: "EGAME",
  casinoType: "FC-EGAME-002",
},
// -------------------ARCADE FC END-----------------


// -------------------ARCADE SPRIBE START-----------------
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-003.png",
  name: "Dice",
  category:"arcade",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-003",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-008.png",
  name: "Mini Roulette",
  category:"arcade",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-008",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-005.png",
  name: "Mines",
  category:"arcade",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-005",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-006.png",
  name: "Hilo",
  category:"arcade",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-006",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-004.png",
  name: "Plinko",
  category:"arcade",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-004",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-002.png",
  name: "Goal",
  category:"arcade",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-002",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-009.png",
  name: "Hotline",
  category:"arcade",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-009",
},
{
  img: "../assets/images/table/spribe/SPRIBE-EGAME-007.png",
  name: "Keno",
  category:"arcade",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-007",
},


// -------------------ARCADE SPRIBE END-----------------

// -------------------FISHING JILI START-----------------
{
  img: "../assets/images/fishing/jili/JILI-FISH-001.png",
  name: "Royal Fishing",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-001",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-002.png",
  name: "Bombing Fishing",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-002",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-003.png",
  name: "Jackpot Fishing",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-003",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-004.png",
  name: "Dinosaur Tycoon",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-004",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-006.png",
  name: "Dragon Fortune",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-006",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-007.png",
  name: "Mega Fishing",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-007",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-008.png",
  name: "Boom Legend",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-008",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-009.png",
  name: "All-star Fishing",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-009",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-011.png",
  name: "Dinosaur Tycoon II",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-011",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-005.png",
  name: "Happy Fishing",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-005",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-012.png",
  name: "Ocean King Jackpot",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-012",
},
{
  img: "../assets/images/fishing/jili/JILI-FISH-013.png",
  name: "Fortune King Jackpot",
  category:"fishing",
  type:"JILI",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-013",
},
// -------------------FISHING JILI START-----------------

// -------------------FISHING FC START---------------

{
  img: "../assets/images/fishing/fc/FC-FISH-005.png",
  name: "STAR HUNTER",
  category:"fishing",
  type:"FC",
  platForm: "FC",
  gameType: "FH",
  casinoType: "FC-FISH-005",
},
{
  img: "../assets/images/fishing/fc/FC-FISH-001.png",
  name: "MONKEY KING FISHING",
  category:"fishing",
  type:"FC",
  platForm: "FC",
  gameType: "FH",
  casinoType: "FC-FISH-001",
},
{
  img: "../assets/images/fishing/fc/FC-FISH-002.png",
  name: "BAO CHUAN FISHING",
  category:"fishing",
  type:"FC",
  platForm: "FC",
  gameType: "FH",
  casinoType: "FC-FISH-002",
},
{
  img: "../assets/images/fishing/fc/FC-FISH-004.png",
  name: "FIERCE FISHING",
  category:"fishing",
  type:"FC",
  platForm: "FC",
  gameType: "FH",
  casinoType: "FC-FISH-004",
},
{
  img: "../assets/images/fishing/fc/FC-FISH-006.png",
  name: "GODS GRANT FORTUNE",
  category:"fishing",
  type:"FC",
  platForm: "FC",
  gameType: "FH",
  casinoType: "FC-FISH-006",
},
// -------------------FISHING FC END-----------------
// -------------------FISHING JDB START-----------------
{
  img: "../assets/images/fishing/jdb/JDB-FISH-008.png",
  name: "Cai Shen Fishing",
  category:"fishing",
  type:"JDB",
  platForm: "JDB",
  gameType: "FH",
  casinoType: "JDB-FISH-008",
},
{
  img: "../assets/images/fishing/jdb/JDB-FISH-009.png",
  name: "Shade Dragons Fishing",
  category:"fishing",
  type:"JDB",
  platForm: "JDB",
  gameType: "FH",
  casinoType: "JDB-FISH-009",
},
{
  img: "../assets/images/fishing/jdb/JDB-FISH-010.png",
  name: "Fishing YiLuFa",
  category:"fishing",
  type:"JDB",
  platForm: "JDB",
  gameType: "FH",
  casinoType: "JDB-FISH-010",
},
{
  img: "../assets/images/fishing/jdb/JDB-FISH-011.png",
  name: "Dragon Master",
  category:"fishing",
  type:"JDB",
  platForm: "JDB",
  gameType: "FH",
  casinoType: "JDB-FISH-011",
},
{
  img: "../assets/images/fishing/jdb/JDB-FISH-012.png",
  name: "Fishing Disco",
  category:"fishing",
  type:"JDB",
  platForm: "JDB",
  gameType: "FH",
  casinoType: "JDB-FISH-012",
},
{
  img: "../assets/images/fishing/jdb/JDB-FISH-013.png",
  name: "Fishing Legend",
  category:"fishing",
  type:"JDB",
  platForm: "JDB",
  gameType: "FH",
  casinoType: "JDB-FISH-013",
},
{
  img: "../assets/images/fishing/jdb/JDB-FISH-014.png",
  name: "Fighter Fire",
  category:"fishing",
  type:"JDB",
  platForm: "JDB",
  gameType: "FH",
  casinoType: "JDB-FISH-014",
},
{
  img: "../assets/images/fishing/jdb/JDB-FISH-015.png",
  name: "Dragon Fishing II",
  category:"fishing",
  type:"JDB",
  platForm: "JDB",
  gameType: "FH",
  casinoType: "JDB-FISH-015",
},
{
  img: "../assets/images/fishing/jdb/JDB-FISH-016.png",
  name: "Dragon Fishing",
  category:"fishing",
  type:"JDB",
  platForm: "JDB",
  gameType: "FH",
  casinoType: "JDB-FISH-016",
},
// -------------------FISHING JDB END-----------------

// -------------------FISHING YELLOWBAT START-----------------

{
  img: "../assets/images/fishing/yellowbat/YesBingo-FH-001.png",
  name: "OceanPhoenix",
  category:"fishing",
  type:"YESBINGO",
  platForm: "YESBINGO",
  gameType: "FH",
  casinoType: "YesBingo-FH-001",
},


// -------------------FISHING YELLOWBAT END-----------------];
// -------------------FISHING JOKER START-----------------

{
  img: "../assets/images/fishing/joker/JOKER-SLOT-097.png",
  name: "Fish Hunter Monster Awaken",
  category:"fishing",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-097",
},
{
  img: "../assets/images/fishing/joker/JOKER-SLOT-154.png",
  name: "Fish Hunter 2 EX - My Club",
  category:"fishing",
  type:"JOKER",
  platForm: "JOKER",
  gameType: "SLOT",
  casinoType: "JOKER-SLOT-154",
},


// -------------------FISHING JOKER END-----------------];
// -------------------FISHING SPADE START-----------------

{
  img: "../assets/images/fishing/spade/SG-FISH-004.webp",
  name: "Zombie Party",
  category:"fishing",
  type:"SPADE",
  platForm: "SPADE",
  gameType: "FH",
  casinoType: "SG-FISH-004",
},

{
  img: "../assets/images/fishing/spade/SG-FISH-001.webp",
  name: "Fishing God",
  category:"fishing",
  type:"SPADE",
  platForm: "SPADE",
  gameType: "FH",
  casinoType: "SG-FISH-001",
},
{
  img: "../assets/images/fishing/spade/SG-FISH-002.webp",
  name: "Fishing War",
  category:"fishing",
  type:"SPADE",
  platForm: "SPADE",
  gameType: "FH",
  casinoType: "SG-FISH-002",
},
{
  img: "../assets/images/fishing/spade/SG-FISH-003.webp",
  name: "Alien Hunter",
  category:"fishing",
  type:"SPADE",
  platForm: "SPADE",
  gameType: "FH",
  casinoType: "SG-FISH-003",
},

// -------------------FISHING SPADE END-----------------;
// -------------------CRASH SPRIBE START-----------------];
{
  img: "../assets/images/crash/spribe/SPRIBE-EGAME-001.png",
  name: "Aviator",
  category:"crash",
  type:"SPRIBE",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-001",
},

// -------------------CRASH SPRIBE END-----------------];


// -------------------CRASH JILI START-----------------];
{
  img: "../assets/images/crash/jili/JILI-TABLE-047.png",
  name: "Crash Bonus",
  category:"crash",
  type:"JILI",
  platForm: "JILI",
  gameType: "TABLE",
  casinoType: "JILI-TABLE-047",
},
{
  img: "../assets/images/crash/jili/JILI-TABLE-058.png",
  name: "Crash Cricket",
  category:"crash",
  type:"JILI",
  platForm: "JILI",
  gameType: "TABLE",
  casinoType: "JILI-TABLE-058",
},
{
  img: "../assets/images/crash/jili/JILI-TABLE-030.png",
  name: "Go Rush",
  category:"crash",
  type:"JILI",
  platForm: "JILI",
  gameType: "TABLE",
  casinoType: "JILI-TABLE-030",
},
{
  img: "../assets/images/crash/jili/JILI-TABLE-035.png",
  name: "Limbo",
  category:"crash",
  type:"JILI",
  platForm: "JILI",
  gameType: "TABLE",
  casinoType: "JILI-TABLE-035",
},
{
  img: "../assets/images/crash/jili/JILI-TABLE-055.png",
  name: "Crash Goal",
  category:"crash",
  type:"JILI",
  platForm: "JILI",
  gameType: "TABLE",
  casinoType: "JILI-TABLE-055",
},
// -------------------CRASH JILI END-----------------];

// -------------------CRASH PP START-----------------];

{
  img: "../assets/images/crash/pp/PP-EGAME-008.png",
  name: "High Flyer ",
  category:"crash",
  type:"PP",
  platForm: "PP",
  gameType: "EGAME",
  casinoType: "PP-EGAME-008",
},
{
  img: "../assets/images/crash/pp/PP-EGAME-001.png",
  name: "Spaceman",
  category:"crash",
  type:"PP",
  platForm: "PP",
  gameType: "EGAME",
  casinoType: "PP-EGAME-001",
},
{
  img: "../assets/images/crash/pp/PP-EGAME-009.png",
  name: "Big Bass Crash",
  category:"crash",
  type:"PP",
  platForm: "PP",
  gameType: "EGAME",
  casinoType: "PP-EGAME-009",
},
// -------------------CRASH PP END-----------------];

// -------------------CRASH KINGMAKER START-----------------];

{
  img: "../assets/images/crash/kingmaker/KM-TABLE-041.png",
  name: "Cash Rocket",
  category:"crash",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-041",
},
{
  img: "../assets/images/crash/kingmaker/KM-TABLE-069.png",
  name: "Elite Aviator Club",
  category:"crash",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-069",
},
{
  img: "../assets/images/crash/kingmaker/KM-TABLE-070.png",
  name: "Interstellar Run",
  category:"crash",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-070",
},
{
  img: "../assets/images/crash/kingmaker/KM-TABLE-075.png",
  name: "Iron Dome",
  category:"crash",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-075",
},
{
  img: "../assets/images/crash/kingmaker/KM-TABLE-076.png",
  name: "TOON CRASH",
  category:"crash",
  type:"KINGMAKER",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-076",
},
// -------------------CRASH KINGMAKER END-----------------];



]



export const bannerSlider = [
  { id: 1, img: banner1 },
  { id: 2, img: banner2 },
  {
    id: 3,
    img: banner3,
  },
];

export const showMenuUsingPath = [{ path: "/change-password" }, { path: "" }];
